.interview_single{
    
}

.interview_head{
    position: relative;
    display: flex;
    justify-content: flex-end;
    
    @include media_query(){
        display: block;
    }
    
    .interview_head_img{
        position: relative;
        width: 75%;
        max-width: 750px;
        //height: 620px;
        
        @include media_query(){
            height: auto;
        }
        
        @include media_query(){
            width: 100%;
        }
        
        img{
            width: 100%;
            height: auto;
            
            @include media_query(){
                
            }
        }
        
        .ma{
            position: absolute;
            right: 0;
            top: 0;
            opacity: 1;
            transition-property: opacity;
            transition-duration: .8s;
        }
        
        .hide{
            opacity: 0;
        }
    }
    
    .interview_head_text{
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
        padding: 50px;
        
        @include media_query(){
            position: relative;
            padding: 0 25px;
            top: -30px;
        }
        
        img{
            max-width: 170px;
        }
    }
    
    .lead_text{
        font-size: 2.8rem;
        font-weight: bold;
        text-decoration: underline;
        line-height: 1.7;
        margin-top: 50px;
        padding-left: 30px;
        
        @include media_query(){
            font-size: 1.9rem;
            margin-top: 30px;
            padding-left: 0;
        }
    }
    
    .personal{
        margin-top: 40px;
        padding-left: 30px;
        
        @include media_query(){
            margin-top: 25px;
            padding-left: 0;
        }
        
        .department{
            display: inline-block;
            font-size: 1.3rem;
            font-weight: bold;
            text-align: center;
            padding: 10px 20px;
            background-color: #DBE600;
            
            @include media_query(){
                font-size: 1.2rem;
            }
        }
        
        .interview_name{
            margin-top: 20px;
            font-size: 2.1rem;
            font-weight: bold;
            
            @include media_query(){
                font-size: 1.8rem;
            }
        }
        
        .join, .faculty{
            font-size: 1.3rem;
            margin-top: 15px;
            
            @include media_query(){
                font-size: 1.2rem;
            }
        }
    }
}

.interview_main{
    margin-top: 80px;
}

.interview_contents{
    display: flex;
    
    @include media_query(){
        display: block;
        padding: 0 25px;
    }
    
    &:not(:last-child){
        margin-bottom: 75px;
    }
    
    &:nth-child(even){
        .interview_text{
            order: 1;
            padding: 0 6.382% 0 0;
            
            @include media_query(){
                padding: 0;
            }
        }
        
        .interview_img{
            margin: 0 6.382% 0 0;
            
            @include media_query(){
                margin: 30px 0 35px 0;
            }
        }
    }
    
    .interview_text{
        flex: 1;
        padding-left: 6.382%;
        
        @include media_query(){
            padding: 0;
        }
        
        h3{
            display: flex;
            align-items: center;
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 30px;
            
            @include media_query(){
                font-size: 1.5rem;
            }
            
            &::before{
                display: block;
                content: "";
                width: 30px;
                height: 1px;
                margin-right: 8px;
                background-color: #000;
            }
        }
        
        p{
            font-size: 1.5rem;
            line-height: 2;
            
            @include media_query(){
                font-size: 1.4rem;
            }
        }
    }

    .interview_img{
        width: 46.808%;
        margin-left: 6.382%;
        
        @include media_query(){
            width: 100%;
            margin: 30px 0 35px 0;
        }
        
        img{
            width: 100%;
            height: auto;
        }
    }
}

.schedule{
    margin-top: 80px;
    
    @include media_query(){
        margin-top: 50px;
    }
    
    h3{
        font-size: 2.4rem;
        text-align: center;
        
        @include media_query(){
            font-size: 1.9rem;
        }
        
        span{
            display: block;
            font-size: 1.3rem;
            color: #59B52F;
            margin-bottom: 10px;
            
            @include media_query(){
                font-size: 1.2rem;
            }
        }
    }
    
    .schedule_img{
        margin: 50px auto 0;
        width: 100%;
        overflow: scroll;
        text-align: center;
        
        @include media_query(){
            margin: 30px auto 0;
            padding: 0 25px;
        }
        
        img{
            width: 100%;
            max-width: 877px;
            height: auto;
            
            @include media_query(){
                width: 180%;
            }
        }
    }
}

.one_day{
    margin-top: 80px;
    
    @include media_query(){
        padding: 0 25px;
    }
    
    h3{
        font-size: 2.4rem;
        text-align: center;
        
        @include media_query(){
            font-size: 1.9rem;
        }
        
        span{
            display: block;
            font-size: 1.3rem;
            color: #59B52F;
            margin-bottom: 10px;
            
            font-size: 1.2rem;
        }
    }
}

.timeline {
    margin-top: 50px;
    list-style: none;
    max-width: 877px;
    margin: 50px auto 0;
    
    li:not(:last-child){
        p{
            padding-bottom: 40px;
        }
    }
    
    li:last-child{
        .timeline-content{
            border-color: transparent;
        }
    }
    
    & > li {
        margin-bottom: 60px;
        
        display: flex;
    }

    & > li {
        margin: 0;
        position: relative;
    }
    
    .timeline-date {
        width: 80px;
        margin-top: 0;
        font-size: 1.8rem;
        margin-top: -5px;
        
        @include media_query(){
            width: 60px;
            font-size: 1.4rem;
        }
    }
    .timeline-content {
        flex: 1;
        border-left: 3px #59B52F solid;
        padding-left: 30px;
        
        @include media_query(){
            padding-left: 20px;
        }
        
        h3{
            font-size: 1.8rem;
            font-weight: bold;
            text-align: left;
            margin-bottom: 10px;
            margin-top: -5px;
            
            @include media_query(){
                font-size: 1.4rem;
            }
        }
        
        p{
            font-size: 1.5rem;
            line-height: 1.8;
            
            @include media_query(){
                font-size: 1.4rem;
            }
        }
        
        &::before {
            content: '';
            width: 12px;
            height: 12px;
            background: #59B52F;
            position: absolute;
            left: 76px;
            top: 0;
            border-radius: 100%;
            
            @include media_query(){
                left: 55px;
            }
        }
    }
}





.other{
    background-color: #EAFAF7;
    padding: 60px 25px;
    margin-top: 100px;
}

.tab_btns{
    display: flex;
    justify-content: center;
    margin-top: 60px;
    
    @include media_query(){
        margin-left: -10px;
    }
    
    .tab_btn{
        width: 216px;
        cursor: pointer;
        
        @include media_query(){
            width: calc(50% - 10px);
            margin-left: 10px;
        }
        
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 216px;
            height: 60px;
            margin: 0 auto;
            border: solid 1px #59B52F;
            background-color: #fff;
            font-size: 1.8rem;
            
            @include media_query(){
                height: 45px;
                font-size: 1.6rem;
            }
            
            &:hover{
                background-color: #59B52F;
                color: #fff;
            }
        }
        
        &:not(:last-child){
            margin-right: 30px;
            
            @include media_query(){
                margin-right: 0;
            }
        }
    }
    
    .on{
        cursor: auto;
        span{
            background-color: #59B52F;
            color: #fff;
        }
    }
}

.tab_area{
    .tab{
        display: none;
    }
    
    .open{
        display: block;
    }
}









.interview_archive{
    
    .interview_archive_head{
        margin-bottom: 70px;
        
        img{
            width: 100%;
            height: auto;
        }
    }
    
    .interview_archive_area{
        margin-top: 105px;
        background-color: #EAFAF7;
        padding: 0 0 130px;
        
        @include media_query(){
            padding: 0 25px 80px;
        }
        
        h4{
            font-size: 2.4rem;
            color: #59B52F;
            margin: 30px 0;
        }
        
        p{
            font-size: 1.5rem;
        }
    }
    
    .interview_archive_area_head{
        max-width: 880px;
        margin: 0 auto;
        
        .interview_archive_title{
            margin-top: -30px;
        }
    }
    
    .tab_area{
        max-width: 880px;
        margin: 0 auto;
    }
    
}