.movies{
    @include media_query(){
        margin: 50px 0;
    }
    
    h2{
        font-size: 2.4rem;
        text-align: center;
        font-weight: bold;
        margin: 1em 0;
    }
    
    .movies_head{
        margin-bottom: 80px;
        
        img{
            width: 100%;
            height: auto;
        }
    }
    
    .section_header{
        .section_title{
            
            span{
                position: relative;
                
                .eyecatch{
                    position: absolute;
                    right: 150%;
                    bottom: -20px;
                    
                    @include media_query(){
                        width: 36px;
                        right: 120%;
                    }
                }
                
                .eyecatch02{
                    position: absolute;
                    left: 150%;
                    bottom: -40px;
                    
                    @include media_query(){
                        width: 64px;
                        left: 110%;
                    }
                }
            }
            
            img{
                max-width: 165px;
                
                @include media_query(){
                    max-width: 143px;
                }
            }
        }
    }
}

.movies_area{
    max-width: 980px;
    margin: 80px auto 0;
    
    @include media_query(){
        padding: 0 20px;
    }
    
    .youtube_wrap{
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 0 -20px;
        
        @include media_query(){
            margin: 0;
        }
    }
    
    .youtube_item{
        width: calc(50% - 20px);
        margin: 0 0 0 20px;
        
        @include media_query(){
            width: 100%;
            margin: 0;
        }
    }
    
    .youtube{
        position: relative;
        z-index: 2;
        width: 100%;
        padding-top: 56.25%;
        margin: 0 auto 30px;
        text-align: center;
        
        iframe{
            position: absolute;
            margin: auto;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}