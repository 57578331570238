.single{
    margin-top: 80px;
    
    @include media_query(){
        margin-top: 50px;
    }
}

.single_head{
    h3{
        font-size: 3rem;
        line-height: 1.3;
        font-weight: bold;
        
        @include media_query(){
            font-size: 2.5rem;
        }
    }
    
    .datetime{
        font-size: 1.6rem;
        margin-top: 20px;
        
        @include media_query(){
            font-size: 1.5rem;
            margin-top: 15px;
        }
    }
}

.single_main{
    margin-top: 80px;
    font-size: 1.6rem;
    line-height: 1.8;
    
    @include media_query(){
        margin-top: 50px;
        font-size: 1.5rem;
    }
    
    h1{
        font-size: 2.5rem;
        font-weight: bold;
        margin: 1em 0;
        
        @include media_query(){
            font-size: 2.2rem;
            margin: .8em 0;
        }
    }
    
    h2{
        font-size: 2rem;
        font-weight: bold;
        margin: 1em 0;
        
        @include media_query(){
            font-size: 1.8rem;
            margin: .8em 0;
        }
    }
    
    h3{
        font-size: 1.8rem;
        font-weight: bold;
        margin: 1em 0;
        
        @include media_query(){
            font-size: 1.6rem;
            margin: .8em 0;
        }
    }
    
    h4, h5, h6{
        font-size: 1.6rem;
        font-weight: bold;
        margin: 1em 0;
        
        @include media_query(){
            font-size: 1.5rem;
            margin: .8em 0;
        }
    }
    
    p{
        & + p{
            margin-top: 1em;
        }
    }
    
    img{
        display: block;
        margin: 10px 0;
        max-width: 100%;
        height: auto;
    }
    
    a{
        text-decoration: underline;
        color: #59B52F;
    }
}

