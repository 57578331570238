@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input, select {
  vertical-align: middle;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

html {
  font-size: 62.5%;
}

body {
  font-family: Verdana, "游ゴシック", "游ゴシック体", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}

h1 {
  font-weight: normal;
}

h2 {
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

h4 {
  font-weight: normal;
}

h5 {
  font-weight: normal;
}

h6 {
  font-weight: normal;
}

header,
footer,
nav,
menu,
article,
aside,
section,
details,
figcaption,
figure {
  display: block;
}

th {
  font-weight: normal;
}

input,
select,
button,
textarea {
  box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=number],
input[type=submit],
input[type=button],
input[type=reset],
textarea,
button,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

input[type=submit],
input[type=button],
input[type=reset],
button,
label[for] {
  cursor: pointer;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

img {
  vertical-align: bottom;
}

a {
  text-decoration: none;
}

/*---------------------------------
    wrap
---------------------------------*/
.wrap {
  max-width: 100%;
  padding-top: 100px;
}
@media screen and (max-width: 820px) {
  .wrap {
    padding: 0;
  }
}

/*---------------------------------
    header
---------------------------------*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
}
@media screen and (max-width: 820px) {
  .header {
    position: absolute;
    background-color: transparent;
  }
  .header .entry_btn {
    display: none;
  }
}
.header .header_inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 20px 25px;
}
@media screen and (max-width: 820px) {
  .header .header_inner {
    height: auto;
    padding: 0 25px 0 0;
  }
}
.header .logo {
  display: flex;
  align-items: center;
}
.header .logo a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 820px) {
  .header .logo .logo_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 88px;
    background-color: #fff;
  }
}
.header .logo .logo_img img {
  width: 72px;
  height: auto;
}
.header .logo .logo_type {
  margin-left: 30px;
}
.header .logo .logo_type img {
  width: 145px;
  height: auto;
}
.header .nav_btn {
  display: none;
}
@media screen and (max-width: 820px) {
  .header .nav_btn {
    display: block;
    margin-left: auto;
  }
  .header .nav_btn img {
    width: 25px;
    height: auto;
  }
}

@media screen and (max-width: 820px) {
  .small_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 15px;
  }
  .small_header .logo .logo_img {
    width: auto;
    height: auto;
    background-color: #fff;
  }
  .small_header .logo .logo_img img {
    width: 32px;
    height: auto;
  }
  .small_header .logo .logo_type {
    margin-left: 20px;
  }
  .small_header .logo .logo_type img {
    width: 95px;
    height: auto;
  }
}

@media screen and (max-width: 820px) {
  .small_header_up {
    transition: 0.3s;
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 820px) {
  .small_header_down {
    transform: translateY(0);
  }
}

.faq_link {
  margin-left: auto;
}
@media screen and (max-width: 820px) {
  .faq_link {
    display: none;
  }
}
.faq_link a {
  font-size: 1.3rem;
  color: #000;
  padding-left: 20px;
  background-image: url(../img/common/icon_arrow_right.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 6px auto;
}
.faq_link a:hover {
  opacity: 0.7;
}
.faq_link + .entry_btn {
  margin-left: 50px;
}

.entry_btn {
  margin-left: auto;
}
.entry_btn a {
  display: block;
  width: 195px;
  padding: 20px;
  font-size: 1.8rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.08em;
  border-radius: 30px;
  background: linear-gradient(-70deg, #59B599, #59B52F);
  transition-property: opacity;
  transition-duration: 0.25s;
}
.entry_btn a:hover {
  opacity: 0.7;
}

.common_btn a {
  display: block;
  width: 195px;
  padding: 20px;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.08em;
  border-radius: 30px;
  background: linear-gradient(-70deg, #59B599, #59B52F);
  transition-property: opacity;
  transition-duration: 0.25s;
}
@media screen and (max-width: 820px) {
  .common_btn a {
    max-width: 240px;
    width: 100%;
  }
}
.common_btn a:hover {
  opacity: 0.7;
}

.return_btn {
  margin-top: 80px;
  text-align: center;
}
.return_btn a {
  display: inline-block;
  width: 195px;
  padding: 20px;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.08em;
  border-radius: 30px;
  background-color: #818181;
  transition-property: opacity;
  transition-duration: 0.25s;
}
@media screen and (max-width: 820px) {
  .return_btn a {
    max-width: 240px;
    width: 100%;
  }
}
.return_btn a:hover {
  opacity: 0.7;
}

/*---------------------------------
    contents
---------------------------------*/
.contents {
  max-width: 100%;
  width: 100%;
  display: flex;
}
@media screen and (max-width: 820px) {
  .contents {
    padding-top: 45px;
  }
}

@media screen and (max-width: 820px) {
  .index_contents {
    padding: 0;
  }
}

/*---------------------------------
    sidebar
---------------------------------*/
.sidebar {
  position: relative;
  width: 80px;
}
@media screen and (max-width: 820px) {
  .sidebar {
    display: none;
  }
}

.nav {
  position: fixed;
  top: 100px;
  left: 0;
  height: calc(100% - 100px);
  padding: 45px 20px 0;
  background-color: #fff;
}
.nav ul li:not(:last-child) {
  margin-bottom: 45px;
}
.nav ul li a:hover {
  opacity: 0.7;
}

.sp_nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #72DBCA;
}
.sp_nav .close_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
}
.sp_nav .close_btn img {
  width: 25px;
  height: auto;
}
.sp_nav .sp_nav_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}
.sp_nav .sp_nav_inner ul li {
  margin-bottom: 20px;
}
.sp_nav .sp_nav_inner ul li a {
  display: block;
  padding: 10px;
}
.sp_nav .sp_nav_inner .entry_btn {
  margin: 0 auto;
}
.sp_nav .sp_corporate {
  display: none;
  margin-top: 40px;
}
@media screen and (max-width: 820px) {
  .sp_nav .sp_corporate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.sp_nav .sp_corporate a {
  font-size: 15px;
  color: #000;
}
.sp_nav .sp_corporate a::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 10px;
  margin-left: 10px;
  background-image: url(../img/common/outer_link_b.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.sp_nav .sp_corporate span {
  font-size: 10px;
  margin-top: 1em;
}

/*---------------------------------
    main
---------------------------------*/
.main {
  max-width: 100%;
  flex: 1;
}
@media screen and (max-width: 820px) {
  .main {
    flex: auto;
  }
}
.main .main_img img, .main .main_img picture {
  width: 100%;
  height: auto;
}

.section_header .section_title {
  text-align: center;
}
.section_header .section_title span {
  position: relative;
}
.section_header .section_title .eyecatch {
  position: absolute;
  right: 150%;
  bottom: -20px;
}
@media screen and (max-width: 820px) {
  .section_header .section_title .eyecatch {
    right: 120%;
  }
}

.pagination {
  margin-top: 50px;
}
.pagination ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination ul li {
  list-style: none;
}
.pagination ul li:not(:last-child) {
  margin-right: 16px;
}
.pagination ul li a, .pagination ul li span {
  display: block;
  color: #000;
  border: solid 1px #C9CACA;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.pagination ul li a:hover, .pagination ul li span:hover {
  border: solid 1px #59B599;
  background: linear-gradient(-70deg, #59B599, #59B52F);
  color: #fff;
}
.pagination ul li span {
  color: #ccc;
  border: solid 1px #ccc;
}
.pagination ul .current span {
  border: solid 1px #59B599;
  background: linear-gradient(-70deg, #59B599, #59B52F);
  color: #fff;
}
.pagination ul .prev a, .pagination ul .prev span, .pagination ul .next a, .pagination ul .next span {
  width: 60px;
}

/*---------------------------------
    footer
---------------------------------*/
.footer {
  margin-top: 120px;
  background-color: #F5F2F0;
}
@media screen and (max-width: 820px) {
  .footer {
    margin-top: 60px;
  }
}
.footer .footer_inner {
  text-align: center;
  padding: 60px 0 25px;
}
@media screen and (max-width: 820px) {
  .footer .footer_inner {
    padding: 30px;
  }
}
.footer .footer_inner .footer_info {
  font-size: 1.5rem;
  line-height: 1.8;
}
@media screen and (max-width: 820px) {
  .footer .footer_inner .footer_info {
    margin-top: 0;
  }
}
.footer .footer_inner p {
  margin-top: 30px;
}
.footer .footer_inner p small {
  font-size: 1rem;
}

.footer_banner {
  margin: 40px 0 55px;
  padding: 0 20px;
}
.footer_banner ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}
@media screen and (max-width: 820px) {
  .footer_banner ul {
    flex-direction: column;
  }
}
.footer_banner ul li {
  list-style: none;
}
.footer_banner ul li img {
  width: auto;
  height: 60px;
  vertical-align: bottom;
}

.foot_btns {
  display: flex;
  justify-content: center;
  margin-top: 110px;
}
@media screen and (max-width: 820px) {
  .foot_btns {
    flex-direction: column;
    margin-top: 100px;
    padding: 0 20px;
  }
}
.foot_btns .recruit_btn {
  margin-right: 55px;
}
@media screen and (max-width: 820px) {
  .foot_btns .recruit_btn {
    margin: 0 0 20px 0;
  }
}
.foot_btns .entry_round_btn {
  margin: 0;
}

.foot_entry_btn, .recruit_btn {
  flex: 1;
  max-width: 335px;
}
@media screen and (max-width: 820px) {
  .foot_entry_btn, .recruit_btn {
    max-width: 100%;
  }
}
.foot_entry_btn a, .recruit_btn a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  text-align: center;
}
.foot_entry_btn a:hover, .recruit_btn a:hover {
  opacity: 0.7;
}
.foot_entry_btn a::before, .recruit_btn a::before {
  position: absolute;
  left: 37px;
  content: "";
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.recruit_btn a {
  background-color: #EAFAF7;
}
.recruit_btn a::before {
  width: 23px;
  height: 60px;
  background-image: url(../img/common/icon_recruit.png);
}
@media screen and (max-width: 820px) {
  .recruit_btn a::before {
    left: auto;
    right: 37px;
  }
}

.foot_entry_btn a {
  background: linear-gradient(-70deg, #59B599, #59B52F);
}
.foot_entry_btn a::before {
  width: 23px;
  height: 68px;
  background-image: url(../img/common/icon_entry.png);
}

.corporate {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 820px) {
  .corporate {
    font-size: 1.4rem;
  }
}
.corporate span {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 16px 0;
}
@media screen and (max-width: 820px) {
  .corporate span {
    font-size: 12px;
  }
}
.corporate span::before {
  display: block;
  content: "";
  width: 6px;
  height: 14px;
  background-image: url(../img/common/icon_slash.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 6px 14px;
  margin-right: 14px;
}
.corporate span::after {
  display: block;
  content: "";
  width: 6px;
  height: 14px;
  background-image: url(../img/common/icon_slash.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 6px 14px;
  margin-left: 14px;
  transform: scale(-1, 1);
}
.corporate a {
  display: block;
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
  padding: 15px;
  border: solid 1px #59B52F;
  border-radius: 30px;
  color: #59B52F;
  background-color: #fff;
}
@media screen and (max-width: 820px) {
  .corporate a {
    font-size: 1.4rem;
  }
}
.corporate a:hover {
  opacity: 0.7;
}
.corporate a::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 10px;
  margin-left: 10px;
  background-image: url(../img/common/outer_link.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.page {
  max-width: 680px;
  margin: 0 auto;
  font-size: 1.5rem;
  line-height: 1.8;
}
.page h1 {
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  margin: 1em 0;
}
.page h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 1em 0;
}
.page h3 {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 1em 0;
}
.page h4, .page h5, .page h6 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1em 0;
}

.notrecruiting {
  padding: 80px 20px;
  font-size: 1.8rem;
  text-align: center;
}

.recruitmanagement_btn {
  margin-top: 20px;
}
.recruitmanagement_btn .recruitmanagement_bunner a {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 60px;
  margin: 0 auto;
  border: solid 3px #59B52F;
  font-size: 1.8rem;
  color: #59B52F;
  font-weight: bold;
  transition: background-color 0.25s, color 0.25s;
}
.recruitmanagement_btn .recruitmanagement_bunner a:hover {
  background-color: #59B52F;
  color: #fff;
}

/*---------------------------------
    introduction
---------------------------------*/
.introduction {
  background-color: #EAFAF7;
  background-image: url(../img/index/introduction_bg.png);
  background-repeat: no-repeat;
  background-position: right 30% top 35%;
  background-size: 132px auto;
  padding: 150px 140px;
}
@media screen and (max-width: 820px) {
  .introduction {
    padding: 50px 25px;
    background-size: 70px auto;
  }
}
.introduction .introduction_title {
  margin-bottom: 60px;
}
.introduction .introduction_title img {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 820px) {
  .introduction .introduction_title img {
    max-width: 302px;
  }
}
.introduction .introduction_text p {
  font-size: 1.7rem;
  line-height: 1.8;
  color: #727171;
}
@media screen and (max-width: 820px) {
  .introduction .introduction_text p {
    font-size: 1.4rem;
  }
}
.introduction .introduction_text p i {
  font-style: normal;
  color: #59B52F;
}
.introduction .introduction_text p + p {
  margin-top: 1em;
}

/*---------------------------------
    news
---------------------------------*/
.news {
  max-width: 680px;
  margin: 0 auto;
  padding: 120px 25px 0;
}
@media screen and (max-width: 820px) {
  .news .section_header .section_title .eyecatch {
    width: 50px;
    height: auto;
  }
  .news .section_header .section_title .section_title_img {
    width: 120px;
    height: auto;
  }
}
.news .news_list {
  margin-top: 30px;
}
.news .news_list ul li {
  list-style: none;
  border-bottom: solid 1px #C9CACA;
}
.news .news_list ul li a {
  display: flex;
  font-size: 1.5rem;
  color: #000;
  padding: 30px 0;
  line-height: 1.3;
}
@media screen and (max-width: 820px) {
  .news .news_list ul li a {
    display: block;
    padding: 25px 0;
  }
}
.news .news_list ul li a:hover {
  opacity: 0.7;
}
.news .news_list ul li a time {
  display: block;
  font-size: 1.4rem;
  color: #898989;
  margin-right: 30px;
}
@media screen and (max-width: 820px) {
  .news .news_list ul li a time {
    margin: 0 0 10px 0;
  }
}
.news .news_list .common_btn {
  margin-top: 50px;
}
.news .news_list .common_btn a {
  margin: 0 auto;
}

.description {
  margin-top: 120px;
}
@media screen and (max-width: 820px) {
  .description {
    margin-top: 80px;
    padding: 0 20px;
  }
}
.description .description_inner {
  max-width: 880px;
  margin: 0 auto;
  border: solid 6px #59b52f;
  border-radius: 30px;
  background-image: url(../img/index/sealandfoam.png);
  background-repeat: no-repeat;
  background-size: 30% auto;
  background-position: right 35px bottom;
}
@media screen and (max-width: 820px) {
  .description .description_inner {
    background-image: url(../img/index/sealandfoam_sp.png);
    background-size: auto 205px;
    background-position: top 5px center;
  }
}
.description .description_text {
  padding: 60px 50px;
}
@media screen and (max-width: 820px) {
  .description .description_text {
    padding: 220px 18px 30px;
  }
}
.description .description_text h2 {
  margin-bottom: 40px;
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.5;
}
@media screen and (max-width: 820px) {
  .description .description_text h2 {
    margin-bottom: 15px;
    font-size: 2rem;
  }
}
.description .description_text h2 span {
  font-size: 4rem;
}
@media screen and (max-width: 820px) {
  .description .description_text h2 span {
    font-size: 2.4rem;
  }
}
.description .description_text p {
  max-width: 70%;
  font-size: 1.6rem;
  line-height: 1.7;
}
@media screen and (max-width: 820px) {
  .description .description_text p {
    max-width: 100%;
    font-size: 1.3rem;
    line-height: 1.6;
    text-align: justify;
  }
}
.description .description_text p span {
  font-size: 1.9rem;
}
@media screen and (max-width: 820px) {
  .description .description_text p span {
    font-size: 1.6rem;
  }
}
.description .description_text .color-b {
  color: #00B0E1;
}
.description .description_text .color-g {
  color: #78C800;
}

.message {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 120px;
}
@media screen and (max-width: 820px) {
  .message {
    margin: 70px auto 0;
    padding: 0;
  }
}
.message .section_header .section_title .eyecatch {
  right: 110%;
}
@media screen and (max-width: 820px) {
  .message .section_header .section_title .eyecatch {
    width: 57px;
    height: auto;
  }
}
@media screen and (max-width: 820px) {
  .message .section_header .section_title .section_title_img {
    width: 184px;
    height: auto;
  }
}

.message_list {
  margin-top: 70px;
}
.message_list .message_item {
  max-width: 940px;
  margin-left: auto;
  display: flex;
}
@media screen and (max-width: 820px) {
  .message_list .message_item {
    position: relative;
    flex-direction: column;
  }
  .message_list .message_item .common_btn {
    position: absolute;
    top: calc(100% + 25px);
    left: 0;
    width: 100%;
  }
  .message_list .message_item .common_btn a {
    margin: 0 auto;
  }
}
.message_list .message_item:nth-child(even) .message_text {
  order: 1;
}
@media screen and (max-width: 820px) {
  .message_list .message_item:nth-child(even) .message_text {
    order: 0;
  }
}
.message_list .message_item:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 820px) {
  .message_list .message_item:not(:last-child) {
    margin-bottom: 145px;
  }
}
.message_list .message_item .message_text {
  flex: 1;
  padding: 50px 20px 50px 30px;
  background-color: #EAFAF7;
}
@media screen and (max-width: 820px) {
  .message_list .message_item .message_text {
    padding: 0 20px;
    background-color: #fff;
  }
}
.message_list .message_item .message_text .message_from {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-left: -75px;
}
@media screen and (max-width: 820px) {
  .message_list .message_item .message_text .message_from {
    font-size: 1.1rem;
    margin-left: -20px;
  }
}
.message_list .message_item .message_text .message_from::before {
  display: block;
  content: "";
  width: 70px;
  height: 1px;
  margin-right: 20px;
  background-color: #000;
}
@media screen and (max-width: 820px) {
  .message_list .message_item .message_text .message_from::before {
    width: 15px;
    margin-right: 5px;
  }
}
.message_list .message_item .message_text h3 {
  font-size: 2.6rem;
  margin: 45px 0;
  line-height: 1.5;
}
@media screen and (max-width: 820px) {
  .message_list .message_item .message_text h3 {
    font-size: 1.9rem;
    margin: 10px 0 20px;
  }
}
@media screen and (max-width: 820px) {
  .message_list .message_item .message_img {
    padding: 0 20px;
  }
}
.message_list .message_item .message_img img {
  max-width: 500px;
  width: 100%;
  height: 420px;
  object-fit: cover;
}
@media screen and (max-width: 820px) {
  .message_list .message_item .message_img img {
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.person {
  margin-top: 120px;
  padding: 120px 0 120px 160px;
  background-color: #EAFAF7;
}
.person .section_header .section_title {
  margin-left: -120px;
}
@media screen and (max-width: 820px) {
  .person .section_header .section_title {
    margin-left: 0;
  }
}
@media screen and (max-width: 820px) {
  .person {
    padding: 75px 0 75px 120px;
  }
  .person .section_header .section_title {
    margin-left: -120px;
  }
  .person .section_header .section_title .section_title_img {
    width: 157px;
    height: auto;
  }
}
.person .interview {
  margin-top: 80px;
  padding: 30px;
  background: linear-gradient(-70deg, #DFF6F3, #EFFFE4);
  border-radius: 30px 0 0 30px;
}
@media screen and (max-width: 820px) {
  .person .interview {
    margin-top: 10px;
    padding: 20px;
  }
}
.person .interview_inner {
  margin-left: -120px;
  background-image: url(../img/index/interview_bg.png);
  background-repeat: no-repeat;
  background-position: right 15% top;
  background-size: 270px;
}
@media screen and (max-width: 820px) {
  .person .interview_inner {
    background-image: none;
  }
  .person .interview_inner h3 img {
    width: 169px;
    height: auto;
  }
}
.person .interview_wrap {
  margin-top: 25px;
  padding: 0 25px 70px;
}
@media screen and (max-width: 820px) {
  .person .interview_wrap {
    padding: 0;
  }
}
.person .interview_wrap h4 {
  font-size: 2.5rem;
  color: #59B52F;
  margin-bottom: 20px;
}
@media screen and (max-width: 820px) {
  .person .interview_wrap h4 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
}
.person .interview_wrap p {
  font-size: 1.6rem;
}
@media screen and (max-width: 820px) {
  .person .interview_wrap p {
    font-size: 1.2rem;
  }
}
.person .interview_wrap .common_btn {
  margin-top: 57px;
  display: flex;
  justify-content: center;
}

.interview_items {
  display: flex;
  flex-wrap: wrap;
  margin: 55px 0 0 -3.409%;
}
@media screen and (max-width: 820px) {
  .interview_items {
    flex-direction: column;
    margin: 40px 0 0;
  }
}

.interview_item {
  width: 29.924%;
  margin-left: 3.409%;
}
.interview_item:not(:nth-last-child(-n+3)) {
  margin-bottom: 20px;
}
@media screen and (max-width: 820px) {
  .interview_item {
    width: 100%;
    margin-left: 0;
  }
  .interview_item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.interview_item a {
  display: block;
  height: 100%;
}
.interview_item a:hover img {
  opacity: 0.7;
}
.interview_item .interview_item_img img {
  width: 100%;
  height: auto;
}
.interview_item .interview_item_text {
  position: relative;
  background-color: #fff;
  padding: 24px 15px;
}
.interview_item .interview_item_text .department {
  position: absolute;
  bottom: 90%;
  display: inline-block;
  font-size: 1.3rem;
  background-color: #DBE600;
  padding: 8px 20px;
  font-weight: bold;
  color: #000;
}
.interview_item .interview_item_text .lead_text {
  font-size: 1.3rem;
  line-height: 1.5;
  margin: 8px 0 12px;
}
.interview_item .interview_item_text h5 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 8px;
  color: #000;
}
.interview_item .interview_item_text p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #000;
}

.culture {
  padding: 110px 0 0 160px;
}
@media screen and (max-width: 820px) {
  .culture {
    padding: 80px 0 0;
  }
}
.culture .section_header .section_title {
  margin-left: -120px;
}
@media screen and (max-width: 820px) {
  .culture .section_header .section_title {
    margin-left: 0;
  }
}
.culture .section_header .section_title .eyecatch {
  right: auto;
  left: 130%;
  bottom: -50px;
}
@media screen and (max-width: 820px) {
  .culture .section_header .section_title .eyecatch {
    left: 90%;
  }
}
@media screen and (max-width: 820px) {
  .culture .section_header .section_title .section_title_img {
    width: 212px;
    height: auto;
  }
}
.culture .culture_inner {
  margin-top: 80px;
  padding: 30px 60px 30px 30px;
  background: linear-gradient(-70deg, #DFF6F3, #EFFFE4);
  border-radius: 30px 0 0 30px;
}
@media screen and (max-width: 820px) {
  .culture .culture_inner {
    margin-top: 15px;
    padding: 80px 20px 25px;
    border-radius: 0;
  }
}

.culture_items {
  margin-left: -100px;
}
@media screen and (max-width: 820px) {
  .culture_items {
    margin-left: 0;
  }
}

.culture_item a {
  display: flex;
  background-color: #fff;
}
@media screen and (max-width: 820px) {
  .culture_item a {
    flex-direction: column;
  }
}
.culture_item a:hover img {
  opacity: 0.7;
}
.culture_item:not(:last-child) {
  margin-bottom: 45px;
}
@media screen and (max-width: 820px) {
  .culture_item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.culture_item .culture_item_img {
  overflow: hidden;
}
.culture_item .culture_item_img img {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 360px;
  object-fit: cover;
}
@media screen and (max-width: 820px) {
  .culture_item .culture_item_img img {
    max-width: 100%;
  }
}
.culture_item .culture_item_text {
  flex: 1;
  padding: 50px 60px;
  background-image: url(../img/common/icon_arrow_right.png);
  background-repeat: no-repeat;
  background-size: 9px 18px;
  background-position: right 60px center;
}
@media screen and (max-width: 820px) {
  .culture_item .culture_item_text {
    padding: 20px;
    background-position: right 20px center;
  }
}
.culture_item .culture_item_text .subtitle {
  font-size: 1.4rem;
  color: #59B52F;
  margin-bottom: 20px;
}
@media screen and (max-width: 820px) {
  .culture_item .culture_item_text .subtitle {
    display: none;
  }
}
.culture_item .culture_item_text .title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #000;
}
@media screen and (max-width: 820px) {
  .culture_item .culture_item_text .title {
    font-size: 1.6rem;
    color: #59B52F;
    margin-bottom: 15px;
  }
}
.culture_item .culture_item_text .lead_text {
  font-size: 1.5rem;
  line-height: 1.8;
  color: #000;
}
@media screen and (max-width: 820px) {
  .culture_item .culture_item_text .lead_text {
    font-size: 1.2rem;
  }
}

.single {
  margin-top: 80px;
}
@media screen and (max-width: 820px) {
  .single {
    margin-top: 50px;
  }
}

.single_head h3 {
  font-size: 3rem;
  line-height: 1.3;
  font-weight: bold;
}
@media screen and (max-width: 820px) {
  .single_head h3 {
    font-size: 2.5rem;
  }
}
.single_head .datetime {
  font-size: 1.6rem;
  margin-top: 20px;
}
@media screen and (max-width: 820px) {
  .single_head .datetime {
    font-size: 1.5rem;
    margin-top: 15px;
  }
}

.single_main {
  margin-top: 80px;
  font-size: 1.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 820px) {
  .single_main {
    margin-top: 50px;
    font-size: 1.5rem;
  }
}
.single_main h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 1em 0;
}
@media screen and (max-width: 820px) {
  .single_main h1 {
    font-size: 2.2rem;
    margin: 0.8em 0;
  }
}
.single_main h2 {
  font-size: 2rem;
  font-weight: bold;
  margin: 1em 0;
}
@media screen and (max-width: 820px) {
  .single_main h2 {
    font-size: 1.8rem;
    margin: 0.8em 0;
  }
}
.single_main h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 1em 0;
}
@media screen and (max-width: 820px) {
  .single_main h3 {
    font-size: 1.6rem;
    margin: 0.8em 0;
  }
}
.single_main h4, .single_main h5, .single_main h6 {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 1em 0;
}
@media screen and (max-width: 820px) {
  .single_main h4, .single_main h5, .single_main h6 {
    font-size: 1.5rem;
    margin: 0.8em 0;
  }
}
.single_main p + p {
  margin-top: 1em;
}
.single_main img {
  display: block;
  margin: 10px 0;
  max-width: 100%;
  height: auto;
}
.single_main a {
  text-decoration: underline;
  color: #59B52F;
}

.interview_head {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 820px) {
  .interview_head {
    display: block;
  }
}
.interview_head .interview_head_img {
  position: relative;
  width: 75%;
  max-width: 750px;
}
@media screen and (max-width: 820px) {
  .interview_head .interview_head_img {
    height: auto;
  }
}
@media screen and (max-width: 820px) {
  .interview_head .interview_head_img {
    width: 100%;
  }
}
.interview_head .interview_head_img img {
  width: 100%;
  height: auto;
}
.interview_head .interview_head_img .ma {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.8s;
}
.interview_head .interview_head_img .hide {
  opacity: 0;
}
.interview_head .interview_head_text {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 100%;
  padding: 50px;
}
@media screen and (max-width: 820px) {
  .interview_head .interview_head_text {
    position: relative;
    padding: 0 25px;
    top: -30px;
  }
}
.interview_head .interview_head_text img {
  max-width: 170px;
}
.interview_head .lead_text {
  font-size: 2.8rem;
  font-weight: bold;
  text-decoration: underline;
  line-height: 1.7;
  margin-top: 50px;
  padding-left: 30px;
}
@media screen and (max-width: 820px) {
  .interview_head .lead_text {
    font-size: 1.9rem;
    margin-top: 30px;
    padding-left: 0;
  }
}
.interview_head .personal {
  margin-top: 40px;
  padding-left: 30px;
}
@media screen and (max-width: 820px) {
  .interview_head .personal {
    margin-top: 25px;
    padding-left: 0;
  }
}
.interview_head .personal .department {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  background-color: #DBE600;
}
@media screen and (max-width: 820px) {
  .interview_head .personal .department {
    font-size: 1.2rem;
  }
}
.interview_head .personal .interview_name {
  margin-top: 20px;
  font-size: 2.1rem;
  font-weight: bold;
}
@media screen and (max-width: 820px) {
  .interview_head .personal .interview_name {
    font-size: 1.8rem;
  }
}
.interview_head .personal .join, .interview_head .personal .faculty {
  font-size: 1.3rem;
  margin-top: 15px;
}
@media screen and (max-width: 820px) {
  .interview_head .personal .join, .interview_head .personal .faculty {
    font-size: 1.2rem;
  }
}

.interview_main {
  margin-top: 80px;
}

.interview_contents {
  display: flex;
}
@media screen and (max-width: 820px) {
  .interview_contents {
    display: block;
    padding: 0 25px;
  }
}
.interview_contents:not(:last-child) {
  margin-bottom: 75px;
}
.interview_contents:nth-child(even) .interview_text {
  order: 1;
  padding: 0 6.382% 0 0;
}
@media screen and (max-width: 820px) {
  .interview_contents:nth-child(even) .interview_text {
    padding: 0;
  }
}
.interview_contents:nth-child(even) .interview_img {
  margin: 0 6.382% 0 0;
}
@media screen and (max-width: 820px) {
  .interview_contents:nth-child(even) .interview_img {
    margin: 30px 0 35px 0;
  }
}
.interview_contents .interview_text {
  flex: 1;
  padding-left: 6.382%;
}
@media screen and (max-width: 820px) {
  .interview_contents .interview_text {
    padding: 0;
  }
}
.interview_contents .interview_text h3 {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 30px;
}
@media screen and (max-width: 820px) {
  .interview_contents .interview_text h3 {
    font-size: 1.5rem;
  }
}
.interview_contents .interview_text h3::before {
  display: block;
  content: "";
  width: 30px;
  height: 1px;
  margin-right: 8px;
  background-color: #000;
}
.interview_contents .interview_text p {
  font-size: 1.5rem;
  line-height: 2;
}
@media screen and (max-width: 820px) {
  .interview_contents .interview_text p {
    font-size: 1.4rem;
  }
}
.interview_contents .interview_img {
  width: 46.808%;
  margin-left: 6.382%;
}
@media screen and (max-width: 820px) {
  .interview_contents .interview_img {
    width: 100%;
    margin: 30px 0 35px 0;
  }
}
.interview_contents .interview_img img {
  width: 100%;
  height: auto;
}

.schedule {
  margin-top: 80px;
}
@media screen and (max-width: 820px) {
  .schedule {
    margin-top: 50px;
  }
}
.schedule h3 {
  font-size: 2.4rem;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .schedule h3 {
    font-size: 1.9rem;
  }
}
.schedule h3 span {
  display: block;
  font-size: 1.3rem;
  color: #59B52F;
  margin-bottom: 10px;
}
@media screen and (max-width: 820px) {
  .schedule h3 span {
    font-size: 1.2rem;
  }
}
.schedule .schedule_img {
  margin: 50px auto 0;
  width: 100%;
  overflow: scroll;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .schedule .schedule_img {
    margin: 30px auto 0;
    padding: 0 25px;
  }
}
.schedule .schedule_img img {
  width: 100%;
  max-width: 877px;
  height: auto;
}
@media screen and (max-width: 820px) {
  .schedule .schedule_img img {
    width: 180%;
  }
}

.one_day {
  margin-top: 80px;
}
@media screen and (max-width: 820px) {
  .one_day {
    padding: 0 25px;
  }
}
.one_day h3 {
  font-size: 2.4rem;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .one_day h3 {
    font-size: 1.9rem;
  }
}
.one_day h3 span {
  display: block;
  font-size: 1.3rem;
  color: #59B52F;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.timeline {
  margin-top: 50px;
  list-style: none;
  max-width: 877px;
  margin: 50px auto 0;
}
.timeline li:not(:last-child) p {
  padding-bottom: 40px;
}
.timeline li:last-child .timeline-content {
  border-color: transparent;
}
.timeline > li {
  margin-bottom: 60px;
  display: flex;
}
.timeline > li {
  margin: 0;
  position: relative;
}
.timeline .timeline-date {
  width: 80px;
  margin-top: 0;
  font-size: 1.8rem;
  margin-top: -5px;
}
@media screen and (max-width: 820px) {
  .timeline .timeline-date {
    width: 60px;
    font-size: 1.4rem;
  }
}
.timeline .timeline-content {
  flex: 1;
  border-left: 3px #59B52F solid;
  padding-left: 30px;
}
@media screen and (max-width: 820px) {
  .timeline .timeline-content {
    padding-left: 20px;
  }
}
.timeline .timeline-content h3 {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
  margin-top: -5px;
}
@media screen and (max-width: 820px) {
  .timeline .timeline-content h3 {
    font-size: 1.4rem;
  }
}
.timeline .timeline-content p {
  font-size: 1.5rem;
  line-height: 1.8;
}
@media screen and (max-width: 820px) {
  .timeline .timeline-content p {
    font-size: 1.4rem;
  }
}
.timeline .timeline-content::before {
  content: "";
  width: 12px;
  height: 12px;
  background: #59B52F;
  position: absolute;
  left: 76px;
  top: 0;
  border-radius: 100%;
}
@media screen and (max-width: 820px) {
  .timeline .timeline-content::before {
    left: 55px;
  }
}

.other {
  background-color: #EAFAF7;
  padding: 60px 25px;
  margin-top: 100px;
}

.tab_btns {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
@media screen and (max-width: 820px) {
  .tab_btns {
    margin-left: -10px;
  }
}
.tab_btns .tab_btn {
  width: 216px;
  cursor: pointer;
}
@media screen and (max-width: 820px) {
  .tab_btns .tab_btn {
    width: calc(50% - 10px);
    margin-left: 10px;
  }
}
.tab_btns .tab_btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 216px;
  height: 60px;
  margin: 0 auto;
  border: solid 1px #59B52F;
  background-color: #fff;
  font-size: 1.8rem;
}
@media screen and (max-width: 820px) {
  .tab_btns .tab_btn span {
    height: 45px;
    font-size: 1.6rem;
  }
}
.tab_btns .tab_btn span:hover {
  background-color: #59B52F;
  color: #fff;
}
.tab_btns .tab_btn:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 820px) {
  .tab_btns .tab_btn:not(:last-child) {
    margin-right: 0;
  }
}
.tab_btns .on {
  cursor: auto;
}
.tab_btns .on span {
  background-color: #59B52F;
  color: #fff;
}

.tab_area .tab {
  display: none;
}
.tab_area .open {
  display: block;
}

.interview_archive .interview_archive_head {
  margin-bottom: 70px;
}
.interview_archive .interview_archive_head img {
  width: 100%;
  height: auto;
}
.interview_archive .interview_archive_area {
  margin-top: 105px;
  background-color: #EAFAF7;
  padding: 0 0 130px;
}
@media screen and (max-width: 820px) {
  .interview_archive .interview_archive_area {
    padding: 0 25px 80px;
  }
}
.interview_archive .interview_archive_area h4 {
  font-size: 2.4rem;
  color: #59B52F;
  margin: 30px 0;
}
.interview_archive .interview_archive_area p {
  font-size: 1.5rem;
}
.interview_archive .interview_archive_area_head {
  max-width: 880px;
  margin: 0 auto;
}
.interview_archive .interview_archive_area_head .interview_archive_title {
  margin-top: -30px;
}
.interview_archive .tab_area {
  max-width: 880px;
  margin: 0 auto;
}

.president_single {
  padding: 80px 0 0;
}
.president_single .section_header .section_title .section_title_img {
  width: 170px;
}

.president_single_img {
  margin-top: 80px;
}
.president_single_img img {
  width: 100%;
  height: auto;
}

.president_single_main_wrap {
  max-width: 940px;
  margin: 0 0 0 auto;
}

.president_single_main {
  max-width: 760px;
  background-color: #EFF8EF;
  border-radius: 30px 0 0 30px;
}
@media screen and (max-width: 820px) {
  .president_single_main {
    max-width: 60%;
    margin: 0 0 0 auto;
  }
}
.president_single_main .president_single_main_inner {
  padding: 60px 60px 60px 0;
  margin: 70px 0 0 -180px;
}
@media screen and (max-width: 820px) {
  .president_single_main .president_single_main_inner {
    padding: 50px 25px 50px 0;
    margin: 50px 0 0 calc(-40vw + 25px);
  }
}
.president_single_main h3 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 80px;
}
@media screen and (max-width: 820px) {
  .president_single_main h3 {
    font-size: 2rem;
    margin: 0 0 70px;
  }
}
.president_single_main h4 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
  margin: 70px 0 40px;
}
@media screen and (max-width: 820px) {
  .president_single_main h4 {
    font-size: 1.7rem;
    margin: 30px 0 20px;
  }
}
.president_single_main p {
  font-size: 1.5rem;
  line-height: 2;
}
@media screen and (max-width: 820px) {
  .president_single_main p {
    font-size: 1.4rem;
  }
}
.president_single_main p + p {
  margin-top: 1em;
}

.president_contents {
  display: flex;
  margin: 60px 0 60px;
}
@media screen and (max-width: 820px) {
  .president_contents {
    display: block;
    margin: 30px 0 30px;
  }
}
.president_contents:nth-of-type(even) .president_img {
  order: 1;
  margin: 0 0 0 55px;
}
@media screen and (max-width: 820px) {
  .president_contents:nth-of-type(even) .president_img {
    margin: 0 0 30px;
  }
}
.president_contents .president_img {
  max-width: 440px;
  margin-right: 55px;
}
@media screen and (max-width: 820px) {
  .president_contents .president_img {
    max-width: 100%;
    margin: 0 0 30px;
  }
}
.president_contents .president_img img {
  width: 100%;
  height: auto;
}
.president_contents .president_text {
  flex: 1;
}
.president_contents .president_text h4 {
  margin: 0 0 40px;
}

.president_history h3 {
  font-size: 1.5rem;
  font-weight: normal;
  text-align: right;
  margin: 50px 0 0;
}
@media screen and (max-width: 820px) {
  .president_history h3 {
    font-size: 1.5rem;
    margin: 30px 0 0;
  }
}
.president_history h3::after {
  display: block;
  content: "";
  width: 50px;
  height: 2px;
  margin: 30px 0 30px auto;
  background-color: #59B52F;
}
@media screen and (max-width: 820px) {
  .president_history h3::after {
    margin: 20px 0 20px auto;
  }
}
.president_history .president_history_text {
  max-width: 380px;
  margin: 0 0 0 auto;
  text-align: justify;
}
@media screen and (max-width: 820px) {
  .president_history .president_history_text p {
    font-size: 1.3rem;
  }
}

.entry_introduction {
  font-size: 1.5rem;
  line-height: 2;
  margin: 80px 0 150px;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .entry_introduction {
    margin: 30px 0 50px;
    padding: 0 25px;
    text-align: left;
    font-size: 1.4rem;
  }
}

.requirements_introduction {
  max-width: 880px;
  font-size: 1.5rem;
  line-height: 2;
  margin: 80px auto 150px;
}
@media screen and (max-width: 820px) {
  .requirements_introduction {
    margin: 30px 0 50px;
    padding: 0 25px;
    text-align: left;
    font-size: 1.4rem;
  }
}
.requirements_introduction h3 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
}

.entry_head {
  margin-bottom: 80px;
}
.entry_head img {
  width: 100%;
  height: auto;
}
.entry_head .section_header .section_title img {
  max-width: 150px;
}

@media screen and (max-width: 820px) {
  .entry_btns {
    padding: 0 20px;
  }
}
.entry_btns ul + ul {
  margin-top: 80px;
}
@media screen and (max-width: 820px) {
  .entry_btns ul + ul {
    margin-top: 30px;
  }
}
.entry_btns ul + .on_text {
  margin-top: 30px;
}
@media screen and (max-width: 820px) {
  .entry_btns ul + .on_text {
    margin-top: 30px;
  }
}
.entry_btns ul li {
  list-style: none;
}
.entry_btns ul li:not(:last-child) {
  margin-bottom: 80px;
}
@media screen and (max-width: 820px) {
  .entry_btns ul li:not(:last-child) {
    margin-bottom: 30px;
  }
}
.entry_btns ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  height: 240px;
  color: #fff;
  font-size: 2.4rem;
  margin: 0 auto;
}
@media screen and (max-width: 820px) {
  .entry_btns ul li a {
    height: 120px;
    flex-direction: column;
    font-size: 1.5rem;
    background-position: center center;
  }
}
.entry_btns ul li a:hover {
  opacity: 0.7;
}
.entry_btns ul .internship a {
  flex-direction: column;
  background-image: url(../img/entry/button_internship.jpg);
  background-size: cover;
}
.entry_btns ul .internship a img {
  max-width: 200px;
  margin-top: 10px;
}
@media screen and (max-width: 820px) {
  .entry_btns ul .internship a img {
    max-width: 120px;
    margin: 10px 0 0 0;
  }
}
.entry_btns ul .freshman a {
  flex-direction: column;
  background-image: url(../img/entry/button_freshman.jpg);
  background-size: cover;
}
.entry_btns ul .freshman a img {
  max-width: 200px;
  margin-top: 10px;
}
@media screen and (max-width: 820px) {
  .entry_btns ul .freshman a img {
    max-width: 120px;
    margin: 10px 0 0 0;
  }
}
.entry_btns ul .career a {
  background-image: url(../img/entry/button_career.jpg);
  background-size: cover;
}
.entry_btns ul .fixed-term a {
  background-image: url(../img/entry/button_fixed-term.jpg);
  background-size: cover;
}

.on_text, .internship_text {
  margin-bottom: 80px;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.5;
}
.on_text + .on_text, .internship_text + .on_text {
  margin-top: 50px;
}
@media screen and (max-width: 820px) {
  .on_text + .on_text, .internship_text + .on_text {
    margin-top: 30px;
  }
}
@media screen and (max-width: 820px) {
  .on_text, .internship_text {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
}

.contact {
  background-color: #EAFAF7;
  margin-top: 150px;
  padding: 100px 0;
}
@media screen and (max-width: 820px) {
  .contact {
    margin-top: 50px;
    padding: 50px 25px;
  }
}

.contact_inner {
  max-width: 680px;
  margin: 0 auto;
}
.contact_inner h2 {
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 820px) {
  .contact_inner h2 {
    margin-bottom: 30px;
  }
}
.contact_inner h2 span {
  display: block;
  font-size: 1.4rem;
  color: #59B52F;
  margin-bottom: 20px;
}
.contact_inner p {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.5;
}

.form_main {
  margin-top: 70px;
}
@media screen and (max-width: 820px) {
  .form_main {
    margin-top: 50px;
  }
}
.form_main p {
  text-align: left;
}
.form_main .required_text {
  font-size: 1.4rem;
  font-weight: bold;
  color: #f00;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: solid 1px #B2B2B2;
}
.form_main p + p {
  margin-top: 20px;
}
.form_main .wpcf7-not-valid-tip {
  margin-top: 10px;
}
.form_main .wpcf7-response-output.wpcf7-validation-errors {
  border: none;
  background-color: #ffcbcb;
  color: #f00;
  font-size: 1.5rem;
  padding: 0.8em 1em;
}
.form_main .wpcf7-response-output.wpcf7-mail-sent-ok {
  border: none;
  background-color: #d5ffcb;
  color: #4dcb46;
  font-size: 1.5rem;
  padding: 0.8em 1em;
}
.form_main label {
  display: block;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: bold;
}
.form_main label + input[type=text], .form_main label + input[type=email], .form_main label + input[type=tel], .form_main label + textarea {
  margin-top: 10px;
}
.form_main input[type=text], .form_main input[type=email], .form_main input[type=tel], .form_main textarea {
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 20px 25px;
  font-size: 1.5rem;
  border: none !important;
}
@media screen and (max-width: 820px) {
  .form_main input[type=text], .form_main input[type=email], .form_main input[type=tel], .form_main textarea {
    padding: 15px 20px;
  }
}
.form_main input[type=text] + label, .form_main input[type=email] + label, .form_main input[type=tel] + label, .form_main textarea + label {
  margin-top: 35px;
}
@media screen and (max-width: 820px) {
  .form_main input[type=text] + label, .form_main input[type=email] + label, .form_main input[type=tel] + label, .form_main textarea + label {
    margin-top: 25px;
  }
}
.form_main input[type=text][readonly], .form_main input[type=email][readonly], .form_main input[type=tel][readonly], .form_main textarea[readonly] {
  background-color: #ececec;
  border: none;
}
.form_main .wpcf7-list-item {
  margin: -10px 0 0;
}
.form_main input[type=submit] {
  display: block;
  width: 195px;
  margin: 50px auto 0;
  padding: 20px;
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.08em;
  border-radius: 30px;
  background: linear-gradient(-70deg, #59B599, #59B52F);
  transition-property: opacity;
  transition-duration: 0.25s;
}
@media screen and (max-width: 820px) {
  .form_main input[type=submit] {
    max-width: 240px;
    width: 100%;
    margin: 50px auto 0;
  }
}
.form_main input[type=submit]:hover {
  opacity: 0.7;
}
.form_main input[type=button] {
  display: block;
  width: 195px;
  margin: 50px auto 0;
  padding: 20px;
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.08em;
  border-radius: 30px;
  background: #a4a4a4;
  transition-property: opacity;
  transition-duration: 0.25s;
}
@media screen and (max-width: 820px) {
  .form_main input[type=button] {
    max-width: 240px;
    width: 100%;
    margin: 50px auto 0;
  }
}
.form_main input[type=button]:hover {
  opacity: 0.7;
}
.form_main .agree_dl {
  margin-top: 70px;
  border-top: solid 1px #B2B2B2;
  padding-top: 20px;
  display: flex;
}
.form_main .agree_dl dt {
  font-size: 1.5rem;
  font-weight: bold;
}
.form_main .agree_dl dd {
  flex: 1;
  font-size: 1.4rem;
  margin-left: 20px;
}
.form_main .agree_dl dd label {
  font-weight: normal;
}
.form_main .agree_dl dd input[type=checkbox] {
  margin-top: -2px;
}
.form_main .agree_dl dd p {
  margin-top: 10px;
}
.form_main .agree_dl dd p a {
  color: #000;
  text-decoration: underline;
}
.form_main .agree_dl dd p a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.requirements_list {
  max-width: 880px;
  margin: 0 auto;
}
@media screen and (max-width: 820px) {
  .requirements_list {
    display: flex;
    padding: 0 20px;
    margin-left: -10px;
  }
}
.requirements_list dl {
  display: flex;
  align-items: center;
  border-top: solid 1px #B2B2B2;
  border-bottom: solid 1px #B2B2B2;
  padding: 0 30px;
}
@media screen and (max-width: 820px) {
  .requirements_list dl {
    display: block;
    width: calc(50% - 10px);
    padding: 25px 10px;
    margin-left: 10px;
  }
}
.requirements_list dl:not(:last-child) {
  margin-bottom: 50px;
}
@media screen and (max-width: 820px) {
  .requirements_list dl:not(:last-child) {
    margin-bottom: 0;
  }
}
.requirements_list dl dt {
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: auto;
}
@media screen and (max-width: 820px) {
  .requirements_list dl dt {
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-align: center;
  }
}
@media screen and (max-width: 820px) {
  .requirements_list dl dd {
    text-align: center;
  }
}
.requirements_list dl dd:not(:last-child) {
  margin-right: 16px;
}
@media screen and (max-width: 820px) {
  .requirements_list dl dd:not(:last-child) {
    margin-right: 0;
  }
}
.requirements_list dl dd a {
  display: block;
  padding: 30px 0;
  font-size: 1.4rem;
  color: #000;
}
@media screen and (max-width: 820px) {
  .requirements_list dl dd a {
    padding: 12px 0;
  }
}
.requirements_list dl dd a[href]:hover {
  opacity: 0.7;
}
.requirements_list dl dd span {
  display: block;
  padding: 30px 0;
  font-size: 1.4rem;
  color: #59B52F;
  font-weight: bold;
}
@media screen and (max-width: 820px) {
  .requirements_list dl dd span {
    padding: 12px 0;
  }
}

.requirements {
  max-width: 880px;
  margin: 80px auto 0;
}
@media screen and (max-width: 820px) {
  .requirements {
    margin: 60px auto 0;
  }
}
.requirements h3 {
  font-size: 2.6rem;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 820px) {
  .requirements h3 {
    font-size: 2rem;
  }
}
.requirements h4 {
  font-size: 2rem;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .requirements h4 {
    font-size: 1.6rem;
  }
}

.requirements_table {
  width: 100%;
  margin-top: 60px;
}
@media screen and (max-width: 820px) {
  .requirements_table {
    display: block;
    margin-top: 30px;
  }
}
@media screen and (max-width: 820px) {
  .requirements_table tr {
    display: block;
  }
}
.requirements_table tr th, .requirements_table tr td {
  padding: 25px 30px;
  text-align: left;
  line-height: 1.8;
}
@media screen and (max-width: 820px) {
  .requirements_table tr th, .requirements_table tr td {
    display: block;
    padding: 15px 20px;
  }
}
.requirements_table tr th {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  border-top: solid 3px #59B52F;
}
@media screen and (max-width: 820px) {
  .requirements_table tr th {
    text-align: center;
    font-size: 1.4rem;
  }
}
.requirements_table tr td {
  font-size: 1.5rem;
  border-top: solid 1px #B2B2B2;
}
@media screen and (max-width: 820px) {
  .requirements_table tr td {
    font-size: 1.4rem;
  }
}

.mynavientry_btn {
  text-align: center;
  margin: 90px 0 0 0;
}

.recruit_schedule {
  max-width: 880px;
  margin: 80px auto 30px;
}
.recruit_schedule h3 {
  font-size: 2.6rem;
  text-align: center;
  margin: 0 0 30px;
}
.recruit_schedule .educational_schedule {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px 0;
}
.recruit_schedule .educational_schedule li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  width: 100%;
}
@media screen and (max-width: 820px) {
  .recruit_schedule .educational_schedule li {
    flex-direction: column;
  }
}
.recruit_schedule .educational_schedule li p {
  width: 100%;
  list-style: none;
  background-color: #F8FACC;
  border: solid 1px #58B32D;
  color: #59B52E;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.5;
  text-align: center;
  padding: 1em;
  margin-bottom: 0;
}
@media screen and (max-width: 820px) {
  .recruit_schedule .educational_schedule li p {
    width: 100%;
    font-size: 1.4rem;
  }
}
.recruit_schedule .educational_schedule li:not(:last-child)::after {
  content: "";
  display: block;
  width: 29px;
  height: 14px;
  margin: 25px 10px;
  background-image: url(../img/culture/arrow_down.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@media screen and (max-width: 820px) {
  .recruit_schedule .educational_schedule li:not(:last-child)::after {
    transform: rotate(0);
    margin: 16px auto;
  }
}

.culture_head {
  margin-bottom: 80px;
}
@media screen and (max-width: 820px) {
  .culture_head {
    margin-bottom: 50px;
  }
}
.culture_head img {
  width: 100%;
  height: auto;
}

.culture_list {
  max-width: 880px;
  margin: 80px auto 0;
}
@media screen and (max-width: 820px) {
  .culture_list {
    display: flex;
    margin: 50px auto 0;
    padding: 0;
  }
}
.culture_list dl {
  display: flex;
  align-items: center;
  border-top: solid 3px #000;
  border-bottom: solid 1px #B2B2B2;
  padding: 0 30px;
}
@media screen and (max-width: 820px) {
  .culture_list dl {
    border-top: solid 1px #B2B2B2;
    width: 100%;
    padding: 0 10px 0;
  }
}
.culture_list dl:not(:last-child) {
  margin-bottom: 50px;
}
@media screen and (max-width: 820px) {
  .culture_list dl:not(:last-child) {
    margin-bottom: 0;
  }
}
.culture_list dl dt {
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: auto;
}
@media screen and (max-width: 820px) {
  .culture_list dl dt {
    display: none;
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-align: center;
  }
}
@media screen and (max-width: 820px) {
  .culture_list dl dd {
    text-align: center;
    margin: 0 auto;
  }
}
.culture_list dl dd:not(:last-child) {
  margin-right: 50px;
}
@media screen and (max-width: 820px) {
  .culture_list dl dd:not(:last-child) {
    margin-right: 0;
  }
}
.culture_list dl dd a {
  display: block;
  padding: 30px 0;
  font-size: 1.4rem;
  color: #000;
}
@media screen and (max-width: 820px) {
  .culture_list dl dd a {
    padding: 20px 0;
  }
}
.culture_list dl dd a:hover {
  opacity: 0.7;
}
.culture_list dl dd span {
  display: block;
  padding: 30px 0;
  font-size: 1.4rem;
  color: #59B52F;
}
@media screen and (max-width: 820px) {
  .culture_list dl dd span {
    padding: 12px 0;
  }
}

.culture_contents {
  max-width: 880px;
  margin: 0 auto;
}

.culture_contents_head {
  margin: 100px 0 0;
}
@media screen and (max-width: 820px) {
  .culture_contents_head {
    margin: 50px 0 0;
    padding: 0 25px;
  }
}
.culture_contents_head h3 {
  text-align: center;
  font-size: 3.6rem;
}
@media screen and (max-width: 820px) {
  .culture_contents_head h3 {
    font-size: 2.6rem;
  }
}
.culture_contents_head h3 span {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  color: #59B52F;
  margin-bottom: 10px;
}
@media screen and (max-width: 820px) {
  .culture_contents_head h3 span {
    font-size: 1.3rem;
  }
}
.culture_contents_head p {
  font-size: 1.5rem;
  margin-top: 50px;
  line-height: 1.8;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .culture_contents_head p {
    margin-top: 30px;
  }
}

.benefits_main + .benefits_main {
  margin-top: 120px;
}
@media screen and (max-width: 820px) {
  .benefits_main + .benefits_main {
    margin-top: 80px;
  }
}
.benefits_main .benefits_main_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -80px;
}
@media screen and (max-width: 820px) {
  .benefits_main .benefits_main_inner {
    margin-left: 0;
    padding: 0 25px;
  }
}
.benefits_main h3 {
  font-size: 2.8rem;
  color: #59B52F;
  font-weight: bold;
  line-height: 1.5;
}
@media screen and (max-width: 820px) {
  .benefits_main h3 {
    font-size: 2.2rem;
    padding: 0 25px;
  }
}
.benefits_main h3::after {
  display: block;
  content: "";
  width: 50px;
  height: 2px;
  margin-top: 30px;
  background-color: #B2B2B2;
}
@media screen and (max-width: 820px) {
  .benefits_main h3::after {
    margin-top: 20px;
  }
}
.benefits_main .benefits_item {
  width: calc(50% - 80px);
  margin-left: 80px;
  margin-top: 80px;
}
@media screen and (max-width: 820px) {
  .benefits_main .benefits_item {
    width: 100%;
    margin: 50px 0 0 0;
  }
}
.benefits_main .benefits_item img {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
@media screen and (max-width: 820px) {
  .benefits_main .benefits_item img {
    margin-bottom: 20px;
  }
}
.benefits_main .benefits_item h4 {
  font-size: 2rem;
  margin-bottom: 20px;
}
@media screen and (max-width: 820px) {
  .benefits_main .benefits_item h4 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
}
.benefits_main .benefits_item h4 span {
  font-size: 1.3rem;
  color: #595757;
}
.benefits_main .benefits_item p {
  font-size: 1.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 820px) {
  .benefits_main .benefits_item p {
    font-size: 1.4rem;
  }
}

.about_educational {
  margin-top: 100px;
}
@media screen and (max-width: 820px) {
  .about_educational {
    margin-top: 60px;
  }
}
.about_educational h3 {
  font-size: 2.8rem;
  color: #59B52F;
  font-weight: bold;
  line-height: 1.5;
}
@media screen and (max-width: 820px) {
  .about_educational h3 {
    font-size: 2.2rem;
    padding: 0 25px;
  }
}
.about_educational h3::after {
  display: block;
  content: "";
  width: 50px;
  height: 2px;
  margin-top: 30px;
  background-color: #B2B2B2;
}
@media screen and (max-width: 820px) {
  .about_educational h3::after {
    margin-top: 20px;
  }
}

.about_educational_inner {
  margin-top: 90px;
  padding: 0 40px;
}
@media screen and (max-width: 820px) {
  .about_educational_inner {
    padding: 0 25px;
  }
}
@media screen and (max-width: 820px) {
  .about_educational_inner {
    margin-top: 50px;
    padding: 0 25px;
  }
}
.about_educational_inner h4 {
  font-size: 2.1rem;
  margin-bottom: 20px;
}
.about_educational_inner p {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 60px;
}
.about_educational_inner img {
  max-width: 640px;
  width: 100%;
  height: auto;
}
.about_educational_inner .educational_schedule li {
  list-style: none;
}
.about_educational_inner .educational_schedule li p {
  list-style: none;
  background-color: #F8FACC;
  border: solid 1px #58B32D;
  color: #59B52E;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.5;
  text-align: center;
  padding: 1em;
  margin-bottom: 0;
}
@media screen and (max-width: 820px) {
  .about_educational_inner .educational_schedule li p {
    font-size: 1.4rem;
  }
}
.about_educational_inner .educational_schedule li:not(:last-child)::after {
  content: "";
  display: block;
  width: 29px;
  height: 14px;
  margin: 25px auto;
  background-image: url(../img/culture/arrow_down.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@media screen and (max-width: 820px) {
  .about_educational_inner .educational_schedule li:not(:last-child)::after {
    margin: 16px auto;
  }
}

.related {
  font-size: 1.4rem;
  line-height: 2;
  margin-top: 60px;
  padding-left: 145px;
}
@media screen and (max-width: 820px) {
  .related {
    padding: 0 25px;
    font-size: 1.2rem;
  }
}
.related h4 {
  display: flex;
  align-items: center;
}
.related h4::before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #59B52F;
  margin-right: 10px;
}
.related h5 {
  margin-top: 10px;
}
.related ul li {
  list-style: none;
}

.slider_wrap {
  margin-top: 60px;
}
.slider_wrap img {
  width: 100%;
  height: auto;
}
.slider_wrap p {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 20px;
}
.slider_wrap .slider li {
  padding: 10px;
}
@media screen and (max-width: 820px) {
  .slider_wrap .slider li {
    padding: 0;
  }
}
.slider_wrap .slick-arrow {
  background-size: cover;
  width: 30px;
  height: 30px;
  z-index: 999;
  margin-top: -22px;
}
.slider_wrap .slick-arrow::before {
  display: none;
}
.slider_wrap .slick-prev {
  left: 20px;
  background: url(../img/common/arrow_l.png);
  background-size: cover;
}
.slider_wrap .slick-prev:hover {
  background-image: url(../img/common/arrow_l.png) !important;
  background-size: cover;
  opacity: 0.7;
}
.slider_wrap .slick-next {
  right: 20px;
  background: url(../img/common/arrow_r.png);
  background-size: cover;
}
.slider_wrap .slick-next:hover {
  background-image: url(../img/common/arrow_r.png) !important;
  background-size: cover;
  opacity: 0.7;
}

.faq .faq_head {
  margin-bottom: 80px;
}
.faq .faq_head img {
  width: 100%;
  height: auto;
}
.faq .section_header {
  position: relative;
  max-width: 695px;
  margin: 0 auto;
}
.faq .section_header .faq_icon_left {
  position: absolute;
  left: 0;
  bottom: -25px;
}
@media screen and (max-width: 820px) {
  .faq .section_header .faq_icon_left {
    left: 30px;
  }
  .faq .section_header .faq_icon_left img {
    width: 38px;
  }
}
.faq .section_header .faq_icon_right {
  position: absolute;
  right: 0;
  bottom: -25px;
}
@media screen and (max-width: 820px) {
  .faq .section_header .faq_icon_right {
    display: none;
  }
}
.faq .section_header .faq_icon_right_sp {
  display: none;
}
@media screen and (max-width: 820px) {
  .faq .section_header .faq_icon_right_sp {
    display: block;
    position: absolute;
    right: 30px;
    bottom: -25px;
  }
}

.faq_main .faq_main_head {
  max-width: 815px;
  margin: 80px auto 0;
}
.faq_main .faq_main_head ul {
  display: flex;
  margin-left: -20px;
}
@media screen and (max-width: 820px) {
  .faq_main .faq_main_head ul {
    margin-left: 0;
    flex-direction: column;
    padding: 0 40px;
  }
}
.faq_main .faq_main_head ul li {
  list-style: none;
  width: calc(33.333% - 20px);
  border: solid 1px #59b52f;
  margin-left: 20px;
}
@media screen and (max-width: 820px) {
  .faq_main .faq_main_head ul li {
    width: 100%;
    margin: 0;
  }
  .faq_main .faq_main_head ul li:not(:last-child) {
    margin-bottom: 20px;
  }
}
.faq_main .faq_main_head ul li a {
  display: block;
  padding: 25px 30px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: #59b52f;
  transition-property: background-color, color;
  transition-duration: 0.25s;
}
@media screen and (max-width: 820px) {
  .faq_main .faq_main_head ul li a {
    font-size: 1.6rem;
    padding: 20px;
  }
}
.faq_main .faq_main_head ul li a:hover {
  background-color: #59b52f;
  color: #fff;
}

.faq_textlink {
  margin: 30px 0 0;
  text-align: center;
}
.faq_textlink a {
  font-size: 1.8rem;
  color: #59b52f;
  text-decoration: underline;
}
.faq_textlink a:hover {
  text-decoration: none;
}

.faq_section {
  padding-top: 120px;
}
@media screen and (max-width: 820px) {
  .faq_section {
    padding-top: 80px;
  }
}
.faq_section .faq_section_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
}
@media screen and (max-width: 820px) {
  .faq_section .faq_section_title {
    font-size: 2.5rem;
  }
}
.faq_section .faq_section_title::after {
  display: block;
  content: "";
  width: 60px;
  height: 1px;
  margin: 50px 0 0 0;
  background-color: #000;
}
@media screen and (max-width: 820px) {
  .faq_section .faq_section_title::after {
    margin: 30px 0 0 0;
  }
}
.faq_section .faq_section_nav {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
@media screen and (max-width: 820px) {
  .faq_section .faq_section_nav {
    flex-direction: column;
    padding: 0 40px;
  }
}
.faq_section .faq_section_nav li {
  list-style: none;
}
.faq_section .faq_section_nav li:not(:last-child) {
  margin-right: 50px;
}
@media screen and (max-width: 820px) {
  .faq_section .faq_section_nav li:not(:last-child) {
    margin-right: 0;
  }
}
@media screen and (max-width: 820px) {
  .faq_section .faq_section_nav li:last-child {
    border-bottom: solid 1px #59b52f;
  }
}
.faq_section .faq_section_nav li a {
  padding: 0 0 5px 0;
  color: #000;
  font-size: 1.6rem;
  font-weight: bold;
  border-bottom: solid 2px transparent;
  transition-property: border-color;
  transition-duration: 0.25s;
}
@media screen and (max-width: 820px) {
  .faq_section .faq_section_nav li a {
    display: block;
    width: 100%;
    text-align: center;
    padding: 18px 0;
    border-top: solid 1px #59b52f;
    border-bottom: none;
  }
}
.faq_section .faq_section_nav li a:hover {
  border-color: #59b52f;
}

.faq_item {
  max-width: 880px;
  padding-top: 100px;
  margin: -20px auto 0;
}
@media screen and (max-width: 820px) {
  .faq_item {
    padding: 0 20px;
    padding-top: 45px;
    margin: -5px auto 0;
  }
}
.faq_item .faq_item_title {
  background-color: #efefef;
  font-size: 1.9rem;
  color: #59b52f;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-bottom: 60px;
}
@media screen and (max-width: 820px) {
  .faq_item .faq_item_title {
    margin-bottom: 20px;
  }
}
.faq_item dl .faq_wrap + .faq_wrap {
  margin-top: 30px;
}
.faq_item dl .faq_wrap dt {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 80px 20px 65px;
  background-image: url(../img/faq/faq_q.png);
  background-repeat: no-repeat;
  background-position: left 20px center;
  background-size: 23px 30px;
  border-bottom: solid 1px #59b52f;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
  cursor: pointer;
}
@media screen and (max-width: 820px) {
  .faq_item dl .faq_wrap dt {
    font-size: 1.6rem;
    padding: 15px 70px 15px 50px;
    background-position: left 10px center;
  }
}
.faq_item dl .faq_wrap dt span {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #59b52f;
}
@media screen and (max-width: 820px) {
  .faq_item dl .faq_wrap dt span {
    width: 50px;
    height: 50px;
  }
}
.faq_item dl .faq_wrap dt span img {
  width: 16px;
  height: auto;
  vertical-align: bottom;
  transform: rotate(180deg);
  transition-property: transform;
  transition-duration: 0.25s;
}
.faq_item dl .faq_wrap dt.open span img {
  transform: rotate(0deg);
}
.faq_item dl .faq_wrap dd {
  display: none;
  padding: 35px 0 0 65px;
  background-image: url(../img/faq/faq_a.png);
  background-repeat: no-repeat;
  background-position: left 20px top 35px;
  background-size: 24px 23px;
  font-size: 1.5rem;
  line-height: 1.8;
}
@media screen and (max-width: 820px) {
  .faq_item dl .faq_wrap dd {
    padding: 15px 0 15px 50px;
    background-position: left 10px top 15px;
  }
}

.entry_round_btn {
  margin-top: 120px;
}
@media screen and (max-width: 820px) {
  .entry_round_btn {
    margin-top: 100px;
  }
}
.entry_round_btn a {
  display: block;
  max-width: 550px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 60px;
}
@media screen and (max-width: 820px) {
  .entry_round_btn a {
    border-radius: 0;
    text-align: center;
  }
}
.entry_round_btn a img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media screen and (max-width: 820px) {
  .entry_round_btn a img {
    width: 335px;
    height: 100px;
    object-fit: cover;
  }
}

@media screen and (max-width: 820px) {
  .movies {
    margin: 50px 0;
  }
}
.movies h2 {
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  margin: 1em 0;
}
.movies .movies_head {
  margin-bottom: 80px;
}
.movies .movies_head img {
  width: 100%;
  height: auto;
}
.movies .section_header .section_title span {
  position: relative;
}
.movies .section_header .section_title span .eyecatch {
  position: absolute;
  right: 150%;
  bottom: -20px;
}
@media screen and (max-width: 820px) {
  .movies .section_header .section_title span .eyecatch {
    width: 36px;
    right: 120%;
  }
}
.movies .section_header .section_title span .eyecatch02 {
  position: absolute;
  left: 150%;
  bottom: -40px;
}
@media screen and (max-width: 820px) {
  .movies .section_header .section_title span .eyecatch02 {
    width: 64px;
    left: 110%;
  }
}
.movies .section_header .section_title img {
  max-width: 165px;
}
@media screen and (max-width: 820px) {
  .movies .section_header .section_title img {
    max-width: 143px;
  }
}

.movies_area {
  max-width: 980px;
  margin: 80px auto 0;
}
@media screen and (max-width: 820px) {
  .movies_area {
    padding: 0 20px;
  }
}
.movies_area .youtube_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -20px;
}
@media screen and (max-width: 820px) {
  .movies_area .youtube_wrap {
    margin: 0;
  }
}
.movies_area .youtube_item {
  width: calc(50% - 20px);
  margin: 0 0 0 20px;
}
@media screen and (max-width: 820px) {
  .movies_area .youtube_item {
    width: 100%;
    margin: 0;
  }
}
.movies_area .youtube {
  position: relative;
  z-index: 2;
  width: 100%;
  padding-top: 56.25%;
  margin: 0 auto 30px;
  text-align: center;
}
.movies_area .youtube iframe {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.workflow {
  margin-top: 150px;
}
@media screen and (max-width: 820px) {
  .workflow {
    margin-top: 100px;
    padding: 0 18px;
  }
}
.workflow h4 {
  display: flex;
  align-items: center;
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 40px;
}
.workflow h4::before {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #59B52F;
  margin-right: 5px;
}
@media screen and (max-width: 820px) {
  .workflow .ofs {
    max-width: 100%;
    height: auto;
    overflow: scroll;
  }
}
.workflow img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media screen and (max-width: 820px) {
  .workflow img {
    height: 475px;
    width: auto;
  }
}

.jobcategorys {
  margin-top: 170px;
}
@media screen and (max-width: 820px) {
  .jobcategorys {
    margin-top: 100px;
    padding: 0 18px;
  }
}
.jobcategorys .jobcategory_item {
  gap: 50px;
  display: grid;
  grid-template-columns: 1fr 270px;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
.jobcategorys .jobcategory_item:nth-child(even) {
  grid-template-columns: 270px 1fr;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item:nth-child(even) {
    grid-template-columns: 1fr;
  }
}
.jobcategorys .jobcategory_item:nth-child(even) .jobcategory_item_text {
  order: 1;
}
.jobcategorys .jobcategory_item .jobcategory_item_text {
  padding-top: 45px;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item .jobcategory_item_text {
    order: 1;
    padding: 0;
  }
}
.jobcategorys .jobcategory_item .jobcategory_item_text h3 {
  font-size: 3.2rem;
  font-weight: bold;
  margin-bottom: 45px;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item .jobcategory_item_text h3 {
    font-size: 2.4rem;
    margin-bottom: 25px;
  }
}
.jobcategorys .jobcategory_item .jobcategory_item_text p {
  font-size: 1.7rem;
  line-height: 1.9;
  font-weight: bold;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item .jobcategory_item_text p {
    font-size: 1.4rem;
  }
}
.jobcategorys .jobcategory_item .jobcategory_item_text .btn_viewmore {
  margin-top: 60px;
}
.jobcategorys .jobcategory_item .jobcategory_item_text .btn_viewmore a {
  display: block;
  width: 240px;
  padding: 20px;
  font-size: 1.3rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.08em;
  border-radius: 30px;
  background: linear-gradient(-70deg, #59B599, #59B52F);
  transition-property: opacity;
  transition-duration: 0.25s;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item .jobcategory_item_text .btn_viewmore a {
    margin: 0 auto;
  }
}
.jobcategorys .jobcategory_item .jobcategory_item_text .btn_viewmore a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item .jobcategory_item_img {
    text-align: center;
  }
}
.jobcategorys .jobcategory_item .jobcategory_item_img img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item .jobcategory_item_img img {
    max-width: 293px;
  }
}
.jobcategorys .jobcategory_item + .jobcategory_item {
  margin-top: 170px;
}
@media screen and (max-width: 820px) {
  .jobcategorys .jobcategory_item + .jobcategory_item {
    margin-top: 100px;
  }
}

.jobcategory_single .jobcategory_single_head {
  margin-top: 50px;
}
.jobcategory_single .jobcategory_single_head .jobcategory_single_illust {
  text-align: center;
}
.jobcategory_single .jobcategory_single_head .jobcategory_single_illust img {
  max-width: 335px;
  height: auto;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_head .jobcategory_single_illust img {
    max-width: 202px;
  }
}
.jobcategory_single .jobcategory_single_head h3 {
  text-align: center;
  font-size: 3.6rem;
  margin-top: 80px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_head h3 {
    font-size: 2.6rem;
    margin-top: 30px;
  }
}
.jobcategory_single .jobcategory_single_head h3 span {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  color: #59B52F;
  margin-bottom: 10px;
}
.jobcategory_single .jobcategory_single_head h3 span::after {
  display: block;
  content: "";
  width: 47px;
  height: 1px;
  background-color: #59B52F;
  margin: 15px auto 0;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_head h3 span {
    font-size: 1.3rem;
  }
}
.jobcategory_single .jobcategory_single_item_wrap {
  margin-top: 150px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap {
    margin-top: 100px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item + .jobcategory_single_item {
  margin-top: 200px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item + .jobcategory_single_item {
    margin-top: 100px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner {
  display: flex;
  gap: 60px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner {
    flex-direction: column;
    padding: 0 20px;
    gap: 25px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_contents {
  flex: 1;
  padding-top: 35px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_contents {
    padding-top: 0;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_contents h4 {
  font-size: 2.4rem;
  font-weight: bold;
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_contents h4 span {
  display: inline-block;
  font-size: 1.6rem;
  color: #59B52F;
  padding: 0.3em 1em;
  border: solid 1px #59B599;
  border-radius: 30px;
  margin-bottom: 10px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_contents h4 {
    font-size: 1.8rem;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_contents p {
  font-size: 1.7rem;
  line-height: 1.9;
  margin-top: 35px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_contents p {
    font-size: 1.4rem;
    margin-top: 20px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_single_item_inner .jobcategory_single_item_img img {
  width: 100%;
  max-width: 440px;
  height: auto;
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview {
  margin-top: 60px;
  position: relative;
  background-color: #E3F8F4;
  padding: 40px 50px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview {
    margin-top: 50px;
    padding: 25px 20px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview h5 {
  position: absolute;
  left: 30px;
  top: -18px;
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview h5 img {
  max-width: 170px;
  height: auto;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview h5 {
    left: auto;
    right: 20px;
    top: -10px;
  }
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview h5 img {
    max-width: 140px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents {
  display: grid;
  grid-template-columns: 190px 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "img title" "img text";
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents {
    grid-template-columns: 128px 1fr;
    grid-template-areas: "img title" "text text";
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents .jobcategory_interview_img {
  width: 100%;
  max-width: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
  aspect-ratio: 1/1;
  grid-area: img;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents .jobcategory_interview_img {
    grid-area: img;
    margin-right: 25px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents .jobcategory_interview_img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents .jobcategory_interview_img img {
    max-width: 103px;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents h6 {
  grid-area: title;
  font-size: 1.8rem;
  line-height: 1.8;
  font-weight: bold;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents h6 {
    grid-area: title;
    padding-top: 20px;
    font-size: 1.5rem;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents p {
  grid-area: text;
  font-size: 1.4rem;
  line-height: 1.8;
  margin-top: 15px;
}
@media screen and (max-width: 820px) {
  .jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents p {
    grid-area: text;
  }
}
.jobcategory_single .jobcategory_single_item_wrap .jobcategory_single_item .jobcategory_interview .jobcategory_interview_contents p span {
  display: block;
  font-size: 1.4rem;
  line-height: 1.8;
  margin-top: 15px;
}

.privacy {
  margin-top: 80px;
  padding: 0 20px;
}
.privacy h2 .section_title_img {
  width: 100%;
  max-width: 350px;
  height: auto;
}
@media screen and (max-width: 820px) {
  .privacy {
    margin-top: 50px;
  }
}

.privacy_head h3 {
  font-size: 3rem;
  line-height: 1.3;
  font-weight: bold;
}
@media screen and (max-width: 820px) {
  .privacy_head h3 {
    font-size: 2.5rem;
  }
}
.privacy_head .datetime {
  font-size: 1.6rem;
  margin-top: 20px;
}
@media screen and (max-width: 820px) {
  .privacy_head .datetime {
    font-size: 1.5rem;
    margin-top: 15px;
  }
}

.privacy_single_main {
  max-width: 880px;
  margin: 80px auto 0;
  font-size: 1.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 820px) {
  .privacy_single_main {
    margin-top: 50px;
    font-size: 1.4rem;
  }
}
.privacy_single_main ol {
  padding-left: 1.8em;
}
.privacy_single_main ol > li {
  font-weight: bold;
}
.privacy_single_main ol > li + li {
  margin-top: 30px;
}
.privacy_single_main ul {
  margin-top: 10px;
}
.privacy_single_main ul li {
  font-weight: normal;
  list-style: none;
  padding-left: 1.8em;
  text-indent: -1.8em;
}
.privacy_single_main ul li + li {
  margin-top: 10px;
}
.privacy_single_main ul li ul li + li {
  margin: 0;
}
.privacy_single_main a {
  text-decoration: underline;
  color: #333;
}

.privacy_link a {
  font-size: 1.4rem;
  text-decoration: underline;
  color: #333;
}
.privacy_link a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.privacy_footer {
  margin-top: 100px;
}
.privacy_footer h3 {
  font-weight: bold;
  margin-bottom: 1em;
}