/*---------------------------------
    introduction
---------------------------------*/
.introduction{
    background-color: #EAFAF7;
    background-image: url(../img/index/introduction_bg.png);
    background-repeat: no-repeat;
    background-position: right 30% top 35%;
    background-size: 132px auto;
    padding: 150px 140px;
    
    @include media_query(){
        padding: 50px 25px;
        background-size: 70px auto;
    }
    
    .introduction_title{
        margin-bottom: 60px;
        
        img{
            max-width: 100%;
            height: auto;
            
            @include media_query(){
                max-width: 302px;
            }
        }
    }
    
    .introduction_text{
        p{
            font-size: 1.7rem;
            line-height: 1.8;
            color: #727171;
            
            @include media_query(){
                font-size: 1.4rem;
            }
            
            i{
                font-style: normal;
                color: #59B52F;
            }
        }
        
        p + p{
            margin-top: 1em;
        }
    }    
}





/*---------------------------------
    news
---------------------------------*/
.news{
    max-width: 680px;
    margin: 0 auto;
    padding: 120px 25px 0;
    
    .section_header .section_title{
        @include media_query(){
            .eyecatch{
                width: 50px;
                height: auto;
            }
            
            .section_title_img{
                width: 120px;
                height: auto;
            }
        }
    }
    
    .news_list{
        margin-top: 30px;
        
        ul{
            li{
                list-style: none;
                border-bottom: solid 1px #C9CACA;
                
                a{
                    display: flex;
                    font-size: 1.5rem;
                    color: #000;
                    padding: 30px 0;
                    line-height: 1.3;
                    
                    @include media_query(){
                        display: block;
                        padding: 25px 0;
                    }
                    
                    &:hover{
                        opacity: .7;
                    }
                    
                    time{
                        display: block;
                        font-size: 1.4rem;
                        color: #898989;
                        margin-right: 30px;
                        
                        @include media_query(){
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }
        }
        
        .common_btn{
            margin-top: 50px;
            
            a{
                margin: 0 auto;
            }
        }
    }
}




.description{
    margin-top: 120px;
    // padding: 0 25px;
    
    @include media_query(){
        margin-top: 80px;
        padding: 0 20px;
    }
    
    .description_inner{
        max-width: 880px;
        // height: 442px;
        margin: 0 auto;
        border: solid 6px #59b52f;
        border-radius: 30px;
        // padding-right: 315px;
        
        // @include media_query(){
        //     width: 100%;
        //     max-width: 325px;
        //     height: auto;
        //     min-height: 525px;
        //     padding: 0;
        // }
        
        background-image: url(../img/index/sealandfoam.png);
        background-repeat: no-repeat;
        background-size: 30% auto;
        background-position: right 35px bottom;
        
        @include media_query(){
            background-image: url(../img/index/sealandfoam_sp.png);
            background-size: auto 205px;
            background-position: top 5px center;
        }
        
        // @include media_query(){
        //     background-image: url(../img/index/description_bg_sp.png);
        //     background-size: contain;
        // }
    }
    
    .description_text{
        padding: 60px 50px;
        
        @include media_query(){
            padding: 220px 18px 30px;
        }
        
        
        h2{
            margin-bottom: 40px;
            font-size: 3.4rem;
            font-weight: bold;
            line-height: 1.5;
            
            @include media_query(){
                margin-bottom: 15px;
                font-size: 2rem;
            }
            
            span{
                font-size: 4rem;
                
                @include media_query(){
                    font-size: 2.4rem;
                }
            }
        }
        
        p{
            max-width: 70%;
            font-size: 1.6rem;
            line-height: 1.7;
            
            @include media_query(){
                max-width: 100%;
                font-size: 1.3rem;
                line-height: 1.6;
                text-align: justify;
            }
            
            span{
                font-size: 1.9rem;
                
                @include media_query(){
                    font-size: 1.6rem;
                }
            }
        }
        
        .color-b{
            color: #00B0E1;
        }
        
        .color-g{
            color: #78C800;
        }
    }
}





.message{
    max-width: 1080px;
    margin: 0 auto;
    padding-top: 120px;
    
    @include media_query(){
        margin: 70px auto 0;
        padding: 0;
    }
    
    .section_header{
        .section_title{
            .eyecatch{
                right: 110%;
                
                @include media_query(){
                    width: 57px;
                    height: auto;
                }
            }
            
            .section_title_img{
                @include media_query(){
                    width: 184px;
                    height: auto;
                }
            }
        }
    }
}

.message_list{
    margin-top: 70px;
    
    .message_item{
        max-width: 940px;
        margin-left: auto;
        display: flex;
        
        @include media_query(){
            position: relative;
            flex-direction: column;
            
            .common_btn{
                position: absolute;
                top: calc(100% + 25px);
                left: 0;
                width: 100%;
                
                a{
                    margin: 0 auto;
                }
            }
        }
        
        &:nth-child(even){
            .message_text{
                order: 1;
                
                @include media_query(){
                    order: 0;
                }
            }
        }
        
        &:not(:last-child){
            margin-bottom: 40px;
            
            @include media_query(){
                margin-bottom: 145px;
            }
        }
        
        .message_text{
            flex: 1;
            padding: 50px 20px 50px 30px;
            background-color: #EAFAF7;
            
            @include media_query(){
                padding: 0 20px;
                background-color: #fff;
            }
            
            .message_from{
                display: flex;
                align-items: center;
                font-size: 1.4rem;
                margin-left: -75px;
                
                @include media_query(){
                    font-size: 1.1rem;
                    margin-left: -20px;
                }
                
                &::before{
                    display: block;
                    content: "";
                    width: 70px;
                    height: 1px;
                    margin-right: 20px;
                    background-color: #000;
                    
                    @include media_query(){
                        width: 15px;
                        margin-right: 5px;
                    }
                }
            }
            
            h3{
                font-size: 2.6rem;
                margin: 45px 0;
                line-height: 1.5;
                
                @include media_query(){
                    font-size: 1.9rem;
                    margin: 10px 0 20px;
                }
            }
        }
        
        
        
        .message_img{
            @include media_query(){
                padding: 0 20px;
            }
            
            img{
                max-width: 500px;
                width: 100%;
                height: 420px;
                object-fit: cover;
                
                @include media_query(){
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }
            }
        }
    }
}




.person{
    margin-top: 120px;
    padding: 120px 0 120px 160px;
    background-color: #EAFAF7;
    
    .section_header .section_title{
        margin-left: -120px;
        
        @include media_query(){
            margin-left: 0;
        }
    }
    
    @include media_query(){
        padding: 75px 0 75px 120px;
        
        .section_header .section_title{
            margin-left: -120px;
            
            .section_title_img{
                width: 157px;
                height: auto;
            }
        }
    }
    
    .interview{
        margin-top: 80px;
        padding: 30px;
        background: linear-gradient(-70deg, #DFF6F3, #EFFFE4);
        border-radius: 30px 0 0 30px;
        
        @include media_query(){
            margin-top: 10px;
            padding: 20px;
        }
    }
    
    .interview_inner{
        margin-left: -120px;
        background-image: url(../img/index/interview_bg.png);
        background-repeat: no-repeat;
        background-position: right 15% top;
        background-size: 270px;
        
        @include media_query(){
            background-image: none;
            
            h3{
                img{
                    width: 169px;
                    height: auto;
                }
            }
        }
    }
    
    .interview_wrap{
        margin-top: 25px;
        padding: 0 25px 70px;
        
        @include media_query(){
            padding: 0;
        }
        
        h4{
            font-size: 2.5rem;
            color: #59B52F;
            margin-bottom: 20px;
            
            @include media_query(){
                font-size: 1.8rem;
                margin-bottom: 15px;
            }
        }
        
        p{
            font-size: 1.6rem;
            
            @include media_query(){
                font-size: 1.2rem;
            }
        }
        
        .common_btn{
            margin-top: 57px;
            display: flex;
            justify-content: center;
        }
    }
}

.interview_items{
    display: flex;
    flex-wrap: wrap;
    margin: 55px 0 0 -3.409%;
    
    @include media_query(){
        flex-direction: column;
        margin: 40px 0 0;;
    }
}

.interview_item{
    width: calc(33.333% - 3.409%);
    margin-left: 3.409%;
    
    &:not(:nth-last-child(-n+3)) {
        margin-bottom: 20px;
    }
    
    @include media_query(){
        width: 100%;
        margin-left: 0;
        
        &:not(:last-child){
            margin-bottom: 20px;
        }
    }
    
    a{
        display: block;
        height: 100%;
        //background-color: #fff;
        
        &:hover img{
            opacity: .7;
        }
    }
    
    .interview_item_img{
        img{
            width: 100%;
            height: auto;
        }
    }
    
    .interview_item_text{
        position: relative;
        background-color: #fff;
        padding: 24px 15px;
        
        .department{
            position: absolute;
            bottom: 90%;
            display: inline-block;
            font-size: 1.3rem;
            background-color: #DBE600;
            padding: 8px 20px;
            font-weight: bold;
            color: #000;
        }
        
        .lead_text{
            font-size: 1.3rem;
            line-height: 1.5;
            margin: 8px 0 12px;
        }
        
        h5{
            font-size: 1.8rem;
            font-weight: bold;
            line-height: 1.3;
            margin-bottom: 8px;
            color: #000;
        }
        
        p{
            font-size: 1.2rem;
            line-height: 1.5;
            color: #000;
        }
    }
}





.culture{
    padding: 110px 0 0 160px;
    
    @include media_query(){
        padding: 80px 0 0;
    }
    
    .section_header{
        .section_title{
            margin-left: -120px;
            
            @include media_query(){
                margin-left: 0;
            }
            
            .eyecatch{
                right: auto;
                left: 130%;
                bottom: -50px;
                
                @include media_query(){
                    left: 90%;
                }
            }
            
            .section_title_img{
                @include media_query(){
                    width: 212px;
                    height: auto;
                }
            }
        }
    }
    
    .culture_inner{
        margin-top: 80px;
        padding: 30px 60px 30px 30px;
        background: linear-gradient(-70deg, #DFF6F3, #EFFFE4);
        border-radius: 30px 0 0 30px;
        
        @include media_query(){
            margin-top: 15px;
            padding: 80px 20px 25px;
            border-radius: 0;
        }
    }
}

.culture_items{
    margin-left: -100px;
    
    @include media_query(){
        margin-left: 0;
    }
}

.culture_item{
    a{
        display: flex;
        background-color: #fff;
        
        @include media_query(){
            flex-direction: column;
        }
        
        &:hover img{
            opacity: .7;
        }
    }
    
    &:not(:last-child){
        margin-bottom: 45px;
        
        @include media_query(){
            margin-bottom: 20px;
        }
    }
    
    .culture_item_img{
        //max-width: 360px;
        overflow: hidden;
        
        img{
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            max-width: 360px;
            object-fit: cover;
            
            @include media_query(){
                max-width: 100%;
            }
        }
    }
    
    .culture_item_text{
        flex: 1;
        padding: 50px 60px;
        background-image: url(../img/common/icon_arrow_right.png);
        background-repeat: no-repeat;
        background-size: 9px 18px;
        background-position: right 60px center;
        
        @include media_query(){
            padding: 20px;
            background-position: right 20px center;
        }
        
        .subtitle{
            font-size: 1.4rem;
            color: #59B52F;
            margin-bottom: 20px;
            
            @include media_query(){
                display: none;
            }
        }
        
        .title{
            font-size: 2.5rem;
            margin-bottom: 30px;
            color: #000;
            
            @include media_query(){
                font-size: 1.6rem;
                color: #59B52F;
                margin-bottom: 15px;
            }
        }
        
        .lead_text{
            font-size: 1.5rem;
            line-height: 1.8;
            color: #000;
            
            @include media_query(){
                font-size: 1.2rem;
            }
        }
    }
}