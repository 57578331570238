.privacy{
    margin-top: 80px;
    padding: 0 20px;
    
    h2{
        .section_title_img{
            width: 100%;
            max-width: 350px;
            height: auto;
        }
    }
    
    @include media_query(){
        margin-top: 50px;
    }
}

.privacy_head{
    h3{
        font-size: 3rem;
        line-height: 1.3;
        font-weight: bold;
        
        @include media_query(){
            font-size: 2.5rem;
        }
    }
    
    .datetime{
        font-size: 1.6rem;
        margin-top: 20px;
        
        @include media_query(){
            font-size: 1.5rem;
            margin-top: 15px;
        }
    }
}

.privacy_single_main{
    max-width: 880px;
    margin: 80px auto 0;
    font-size: 1.6rem;
    line-height: 1.8;
    
    @include media_query(){
        margin-top: 50px;
        font-size: 1.4rem;
    }
    
    ol{
        padding-left: 1.8em;
        
        &>li{
            font-weight: bold;
            
            & + li{
                margin-top: 30px;
            }
        }
    }
    
    ul{
        margin-top: 10px;
        
        li{
            font-weight: normal;
            list-style: none;
            
            padding-left: 1.8em;
            text-indent: -1.8em;
            
            & + li{
                margin-top: 10px;
            }
            
            ul{
                li{
                    & + li{
                        margin: 0;
                    }
                }
            }
            
        }
    }
    
    a{
        text-decoration: underline;
        color: #333;
    }
}



.privacy_link{
    a{
        font-size: 1.4rem;
        text-decoration: underline;
        color: #333;
        
        &:hover{
            opacity: .7;
            text-decoration: none;
        }
    }
}

.privacy_footer{
    margin-top: 100px;
    
    h3{
        font-weight: bold;
        margin-bottom: 1em;
    }
}