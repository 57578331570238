.faq{
    
    .faq_head{
        margin-bottom: 80px;
        
        img{
            width: 100%;
            height: auto;
        }
    }

    .section_header{
        position: relative;
        max-width: 695px;
        margin: 0 auto;
        
        .faq_icon_left{
            position: absolute;
            left: 0;
            bottom: -25px;
            
            @include media_query(){
                left: 30px;
                
                img{
                    width: 38px;
                }
            }
        }
        
        .faq_icon_right{
            position: absolute;
            right: 0;
            bottom: -25px;
            
            @include media_query(){
                display: none;
            }
        }
        
        .faq_icon_right_sp{
            display: none;
            
            @include media_query(){
                display: block;
                position: absolute;
                right: 30px;
                bottom: -25px;
            }
        }
    }
}

.faq_main{
    
    .faq_main_head{
        max-width: 815px;
        margin: 80px auto 0;
        
        ul{
            display: flex;
            margin-left: -20px;
            
            @include media_query(){
                margin-left: 0;
                flex-direction: column;
                padding: 0 40px;
            }
            
            li{
                list-style: none;
                width: calc(33.333% - 20px);
                border: solid 1px #59b52f;
                margin-left: 20px;
                
                @include media_query(){
                    width: 100%;
                    margin: 0;
                    
                    &:not(:last-child){
                        margin-bottom: 20px;
                    }
                }
                
                a{
                    display: block;
                    padding: 25px 30px;
                    font-size: 1.8rem;
                    font-weight: bold;
                    text-align: center;
                    color: #59b52f;
                    
                    transition-property: background-color, color;
                    transition-duration: .25s;
                    
                    @include media_query(){
                        font-size: 1.6rem;
                        padding: 20px;
                    }
                    
                    &:hover{
                        background-color: #59b52f;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.faq_textlink{
    margin: 30px 0 0;
    text-align: center;
    
    a{
        font-size: 1.8rem;
        color: #59b52f;
        text-decoration: underline;
        
        &:hover{
            text-decoration: none;
        }
    }
}

.faq_section{
    padding-top: 120px;
    //margin-top:-100px;
    
    @include media_query(){
        padding-top: 80px;
    }
    
    .faq_section_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        font-size: 3rem;
        
        @include media_query(){
            font-size: 2.5rem;
        }
        
        &::after{
            display: block;
            content: "";
            width: 60px;
            height: 1px;
            margin: 50px 0 0 0;
            background-color: #000;
            
            @include media_query(){
                margin: 30px 0 0 0;
            }
        }
    }
    
    .faq_section_nav{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        
        @include media_query(){
            flex-direction: column;
            padding: 0 40px;
        }
        
        li{
            list-style: none;
            
            &:not(:last-child){
                margin-right: 50px;
                
                @include media_query(){
                    margin-right: 0;
                }
            }
            
            &:last-child{
                @include media_query(){
                    border-bottom: solid 1px #59b52f;
                }
            }
            
            a{
                padding: 0 0 5px 0;
                color: #000;
                font-size: 1.6rem;
                font-weight: bold;
                border-bottom: solid 2px transparent;
                
                transition-property: border-color;
                transition-duration: .25s;
                
                @include media_query(){
                    display: block;
                    width: 100%;
                    text-align: center;
                    padding: 18px 0;
                    border-top: solid 1px #59b52f;
                    border-bottom: none;
                }
                
                &:hover{
                    border-color: #59b52f;
                }
            }
        }
    }
}

.faq_item{
    max-width: 880px;
    //margin: 80px auto 0;
    padding-top: 100px;
    margin: -20px auto 0;
    
    @include media_query(){
        padding: 0 20px;
        padding-top: 45px;
        margin: -5px auto 0;
    }
    
    .faq_item_title{
        background-color: #efefef;
        font-size: 1.9rem;
        color: #59b52f;
        font-weight: bold;
        text-align: center;
        padding: 10px;
        margin-bottom: 60px;
        
        @include media_query(){
            margin-bottom: 20px;
        }
    }
    
    dl{
        .faq_wrap{
            
            & + .faq_wrap{
                margin-top: 30px;
            }
            
            dt{
                position: relative;
                display: flex;
                align-items: center;
                padding: 20px 80px 20px 65px;
                background-image: url(../img/faq/faq_q.png);
                background-repeat: no-repeat;
                background-position: left 20px center;
                background-size: 23px 30px;
                
                border-bottom: solid 1px #59b52f;
                
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.5;
                
                @include media_query(){
                    font-size: 1.6rem;
                    padding: 15px 70px 15px 50px;
                    background-position: left 10px center;
                }
                
                cursor: pointer;
                
                span{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px;
                    height: 60px;
                    background-color: #59b52f;
                    
                    @include media_query(){
                        width: 50px;
                        height: 50px;
                    }
                    
                    img{
                        width: 16px;
                        height: auto;
                        vertical-align: bottom;
                        transform:rotate(180deg);
                        
                        transition-property: transform;
                        transition-duration: .25s;
                    }
                }
                
                &.open{
                    span img{
                        transform:rotate(0deg);
                    }
                }
            }
            
            dd{
                display: none;
                padding: 35px 0 0 65px;
                background-image: url(../img/faq/faq_a.png);
                background-repeat: no-repeat;
                background-position: left 20px top 35px;
                background-size: 24px 23px;
                
                font-size: 1.5rem;
                line-height: 1.8;
                
                @include media_query(){
                    padding: 15px 0 15px 50px;
                    background-position: left 10px top 15px;
                }
            }
        }
    }
}

.entry_round_btn{
    margin-top: 120px;
    
    @include media_query(){
        margin-top: 100px;
    }
    
    a{
        display: block;
        max-width: 550px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 60px;
        
        @include media_query(){
            border-radius: 0;
            text-align: center;
        }
        
        img{
            width: 100%;
            height: auto;
            vertical-align: bottom;
            
            @include media_query(){
                width: 335px;
                height: 100px;
                object-fit: cover;
            }
        }
    }
}