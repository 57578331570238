body{
    
}

img{
    vertical-align: bottom;
}

a{
    text-decoration: none;
}





/*---------------------------------
    wrap
---------------------------------*/
.wrap{
    max-width: 100%;
    padding-top: 100px;
    
    @include media_query(){
        padding: 0;
    }
}






/*---------------------------------
    header
---------------------------------*/
.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    background-color: #fff;
    
    @include media_query(){
        position: absolute;
        background-color: transparent;
        
        .entry_btn{
            display: none;
        }
    }
    
    .header_inner{
        display: flex;
        align-items: center;
        
        width: 100%;
        height: 100px;
        padding: 20px 25px;
        
        @include media_query(){
            height: auto;
            padding: 0 25px 0 0;
        }
    }
    
    .logo{
        display: flex;
        align-items: center;
        
        a{
            &:hover{
                opacity: .7;
            }
        }
        
        .logo_img{
            @include media_query(){
                display: flex;
                justify-content: center;
                align-items: center;
                width: 88px;
                height: 88px;
                background-color: #fff;
            }
            
            img{
                width: 72px;
                height: auto;
            }
        }
        
        .logo_type{
            margin-left: 30px;
            
            img{
                width: 145px;
                height: auto;
            }
        }
    }
    
    .nav_btn{
        display: none;
        
        @include media_query(){
            display: block;
            margin-left: auto;
            
            img{
                width: 25px;
                height: auto;
            }
        }
    }
}

.small_header{
    @include media_query(){
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px;
        
        .logo{
            .logo_img{
                    width: auto;
                    height: auto;
                    background-color: #fff;
                
                img{
                    width: 32px;
                    height: auto;
                }
            }
            
            .logo_type{
                margin-left: 20px;
                
                img{
                    width: 95px;
                    height: auto;
                }
            }
        }
    }
}

.small_header_up{
    @include media_query(){
        transition: .3s;
        transform: translateY(-100%);
    }
}

.small_header_down{
    @include media_query(){
        transform: translateY(0);
    }
}



.faq_link{
    margin-left: auto;
    
    @include media_query(){
        display: none;
    }
    
    a{
        font-size: 1.3rem;
        color: #000;
        padding-left: 20px;
        background-image: url(../img/common/icon_arrow_right.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 6px auto;
        
        &:hover{
            opacity: .7;
        }
    }
    
    & + .entry_btn{
        margin-left: 50px;
    }
}

.entry_btn{
    //margin-left: 50px;
    margin-left: auto;
    
    a{
        display: block;
        width: 195px;
        padding: 20px;
        font-size: 1.8rem;
        text-align: center;
        color: #fff;
        letter-spacing: .08em;
        
        border-radius: 30px;
        background: linear-gradient(-70deg, #59B599, #59B52F);
        
        transition-property: opacity;
        transition-duration: .25s;
        
        &:hover{
            opacity: .7;
        }
    }
}




.common_btn{
    
    a{
        display: block;
        width: 195px;
        padding: 20px;
        font-size: 1.2rem;
        text-align: center;
        color: #fff;
        letter-spacing: .08em;
        
        border-radius: 30px;
        background: linear-gradient(-70deg, #59B599, #59B52F);
        
        transition-property: opacity;
        transition-duration: .25s;
        
        @include media_query(){
            max-width: 240px;
            width: 100%;
        }
        
        &:hover{
            opacity: .7;
        }
    }
}




.return_btn{
    margin-top: 80px;
    text-align: center;
    
    a{
        display: inline-block;
        width: 195px;
        padding: 20px;
        font-size: 1.2rem;
        text-align: center;
        color: #fff;
        letter-spacing: .08em;
        
        border-radius: 30px;
        background-color: #818181;
        
        transition-property: opacity;
        transition-duration: .25s;
        
        @include media_query(){
            max-width: 240px;
            width: 100%;
        }
        
        &:hover{
            opacity: .7;
        }
    }
}




/*---------------------------------
    contents
---------------------------------*/
.contents{
    max-width: 100%;
    width: 100%;
    display: flex;
    //max-width: 1440px;
    
    @include media_query(){
        padding-top: 45px;
    }
}

.index_contents{
    @include media_query(){
        padding: 0;
    }
}





/*---------------------------------
    sidebar
---------------------------------*/
.sidebar{
    position: relative;
    width: 80px;
    
    @include media_query(){
        display: none;
    }
    
}





.nav{
    position: fixed;
    top: 100px;
    left: 0;
    
    height: calc(100% - 100px);
    padding: 45px 20px 0;
    background-color: #fff;
    
    ul{
        li{
            &:not(:last-child){
                margin-bottom: 45px;
            }
            
            a{
                &:hover{
                    opacity: .7;
                }
            }
        }
    }
}




.sp_nav{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: #72DBCA;
    
    .close_btn{
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 10px;
        
        img{
            width: 25px;
            height: auto;
        }
    }
    
    .sp_nav_inner{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        text-align: center;
        
        ul{
            li{
                margin-bottom: 20px;
                
                a{
                    display: block;
                    padding: 10px;
                }
            }
        }
        
        .entry_btn{
            margin: 0 auto;
        }
    }
    
    .sp_corporate{
        display: none;
        margin-top: 40px;
        
        @include media_query(){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
        a{
            font-size: 15px;
            color: #000;
            
            &::after{
                content: "";
                display: inline-block;
                width: 14px;
                height: 10px;
                margin-left: 10px;
                background-image: url(../img/common/outer_link_b.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
            }
        }
        
        span{
            font-size: 10px;
            margin-top: 1em;
        }
    }
}





/*---------------------------------
    main
---------------------------------*/
.main{
    max-width: 100%;
    flex: 1;
    
    @include media_query(){
        flex: auto;
    }
    
    .main_img{
        img,picture{
            width: 100%;
            height: auto;
        }
    }
}





.section_header{
    .section_title{
        text-align: center;
        
        span{
            position: relative;
        }
        
        .eyecatch{
            position: absolute;
            right: 150%;
            bottom: -20px;
            
            @include media_query(){
                right: 120%;
            }
        }
    }
}




.pagination{
    margin-top: 50px;
    
    ul{
        display: flex;
        align-items: center;
        justify-content: center;
        
        li{
            list-style: none;
            
            &:not(:last-child){
                margin-right: 16px;
            }
            
            a,span{
                display: block;
                color: #000;
                border: solid 1px #C9CACA;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                
                &:hover{
                    border: solid 1px #59B599;
                    background: linear-gradient(-70deg, #59B599, #59B52F);
                    color: #fff;
                }
            }
            
            span{
                color: #ccc;
                border: solid 1px #ccc;
            }
        }
        
        .current{
            span{
                border: solid 1px #59B599;
                background: linear-gradient(-70deg, #59B599, #59B52F);
                color: #fff;
            }
        }
        
        .prev,.next{
            a, span{
                width: 60px;
            }
        }
    }
}





/*---------------------------------
    footer
---------------------------------*/
.footer{
    margin-top: 120px;
    background-color: #F5F2F0;
    
    @include media_query(){
        margin-top: 60px;
    }
    
    .footer_inner{
        text-align: center;
        padding: 60px 0 25px;
        
        @include media_query(){
            padding: 30px;
        }
        
        .footer_info{
            font-size: 1.5rem;
            line-height: 1.8;
            
            @include media_query(){
                margin-top: 0;
            }
        }
        
        p{
            margin-top: 30px;
            
            small{
                font-size: 1rem;
            }
        }
    }
}





.footer_banner{
    margin: 40px 0 55px;
    padding: 0 20px;
    
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;
        
        @include media_query(){
            flex-direction: column;
        }
        
        li{
            list-style: none;
            
            img{
                width: auto;
                height: 60px;
                vertical-align: bottom;
            }
        }
    }
}




.foot_btns{
    display: flex;
    justify-content: center;
    margin-top: 110px;
    
    @include media_query(){
        flex-direction: column;
        margin-top: 100px;
        padding: 0 20px;
    }
    
    .recruit_btn{
        margin-right: 55px;
        
        @include media_query(){
            margin: 0 0 20px 0;
        }
    }
    
    .entry_round_btn{
        margin: 0;
    }
}

%foot_btn{
    flex: 1;
    max-width: 335px;
    
    @include media_query(){
        max-width: 100%;
    }
    
    a{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        text-align: center;
        
        &:hover{
            opacity: .7;
        }
        
        &::before{
            position: absolute;
            left: 37px;
            content: "";
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.recruit_btn{
    @extend %foot_btn;
    
    a{
        background-color: #EAFAF7;
        
        &::before{
            width: 23px;
            height: 60px;
            background-image: url(../img/common/icon_recruit.png);
            
            @include media_query(){
                left: auto;
                right: 37px;
            }
        }
    }
}

.foot_entry_btn{
    @extend %foot_btn;
    
    a{
        background: linear-gradient(-70deg, #59B599, #59B52F);
        
        &::before{
            width: 23px;
            height: 68px;
            background-image: url(../img/common/icon_entry.png);
        }
    }
}

.corporate{
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @include media_query(){
        font-size: 1.4rem;
    }
    
    span{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        margin: 0 0 16px 0;
        
        @include media_query(){
            font-size: 12px;
        }
        
        &::before{
            display: block;
            content: "";
            width: 6px;
            height: 14px;
            background-image: url(../img/common/icon_slash.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 6px 14px;
            margin-right: 14px;
        }
        
        &::after{
            display: block;
            content: "";
            width: 6px;
            height: 14px;
            background-image: url(../img/common/icon_slash.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 6px 14px;
            margin-left: 14px;
            transform: scale(-1, 1);
        }
    }
    
    a{
        display: block;
        width: 100%;
        max-width: 335px;
        margin: 0 auto;
        padding: 15px;
        border: solid 1px #59B52F;
        border-radius: 30px;
        color: #59B52F;
        background-color: #fff;
        
        @include media_query(){
            font-size: 1.4rem;
        }
        
        &:hover{
            opacity: .7;
        }
        
        &::after{
            content: "";
            display: inline-block;
            width: 14px;
            height: 10px;
            margin-left: 10px;
            background-image: url(../img/common/outer_link.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }
}




.page{
    max-width: 680px;
    margin: 0 auto;
    font-size: 1.5rem;
    line-height: 1.8;
    
    h1{
        font-size: 2.4rem;
        text-align: center;
        font-weight: bold;
        margin: 1em 0;
    }
    
    h2{
        font-size: 1.8rem;
        font-weight: bold;
        margin: 1em 0;
    }
    
    h3{
        font-size: 1.6rem;
        font-weight: bold;
        margin: 1em 0;
    }
    
    h4,h5,h6{
        font-size: 1.5rem;
        font-weight: bold;
        margin: 1em 0;
    }
}




.notrecruiting{
    padding: 80px 20px;
    font-size: 1.8rem;
    text-align: center;
}




.recruitmanagement_btn{
    margin-top: 20px;
    
    .recruitmanagement_bunner{
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 300px;
            height: 60px;
            margin: 0 auto;
            border: solid 3px #59B52F;
            font-size: 1.8rem;
            color: #59B52F;
            font-weight: bold;
            transition: background-color .25s, color .25s;
            
            &:hover{
                background-color: #59B52F;
                color: #fff;
            }
        }
    }
}