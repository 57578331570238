///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-family: Verdana, "游ゴシック", '游ゴシック体', YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$line-height: 1.5;




///*------------------------------------*//
//    Colors
//*------------------------------------*//
$main-color: #fff;
$accent-color: #f00;
$font-color: #000;
$base-color: #fff;
$bg-color: #fff;




///*------------------------------------*//
//    path
//*------------------------------------*//
$img_path:'./htdocs/common/img/';







///*------------------------------------*//
//     Placeholder selector
//*------------------------------------*//

//clearfix
%cf{
	&:after {
    content:"";
    display:block;
    clear:both;
  }
}



///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media_query($width:820){
	@media screen and (max-width: $width+px){
		@content;
	}
}
