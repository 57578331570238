.president_single{
    padding: 80px 0 0;
    
    .section_header{
        .section_title{
            .section_title_img{
                width: 170px;
            }
        }
    }
}

.president_single_img{
    //max-width: 940px;
    margin-top: 80px;
    
    img{
        width: 100%;
        height: auto;
    }
}

.president_single_main_wrap{
    max-width: 940px;
    margin: 0 0 0 auto;
}

.president_single_main{
    max-width: 760px;
    background-color: #EFF8EF;
    border-radius: 30px 0 0 30px;
    //margin: 0 auto;
    
    @include media_query(){
        max-width: 60%;
        margin: 0 0 0 auto;
    }
    
    .president_single_main_inner{
        padding: 60px 60px 60px 0;
        margin: 70px 0 0 calc(760px - 940px);
        
        @include media_query(){
            padding: 50px 25px 50px 0;
            margin: 50px 0 0 calc(-40vw + 25px);
        }
    }
    
    h3{
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.5;
        margin: 0 0 80px;
        
        @include media_query(){
            font-size: 2rem;
            margin: 0 0 70px;
        }
    }
    
    h4{
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.5;
        margin: 70px 0 40px;
        
        @include media_query(){
            font-size: 1.7rem;
            margin: 30px 0 20px;
        }
    }
    
    p{
        font-size: 1.5rem;
        line-height: 2;
        
        @include media_query(){
            font-size: 1.4rem;
        }
        
        & + p{
            margin-top: 1em;
        }
    }
}

.president_contents{
    display: flex;
    margin: 60px 0 60px;
    
    @include media_query(){
        display: block;
        margin: 30px 0 30px;
    }
    
    &:nth-of-type(even){
        .president_img{
            order: 1;
            margin: 0 0 0 55px;
            
            @include media_query(){
                margin: 0 0 30px;
            }
        }
    }
    
    .president_img{
        max-width: 440px;
        margin-right: 55px;
        
        @include media_query(){
            max-width: 100%;
            margin: 0 0 30px;
        }
        
        img{
            width: 100%;
            height: auto;
        }
    }
    
    .president_text{
        flex: 1;
        
        h4{
            margin: 0 0 40px;
        }
    }
}

.president_history{
    h3{
        font-size: 1.5rem;
        font-weight: normal;
        text-align: right;
        margin: 50px 0 0;
        
        @include media_query(){
            font-size: 1.5rem;
            margin: 30px 0 0;
        }
        
        &::after{
            display: block;
            content: "";
            width: 50px;
            height: 2px;
            margin: 30px 0 30px auto;
            background-color: #59B52F;
            
            @include media_query(){
                margin: 20px 0 20px auto;
            }
        }
    }
    
    .president_history_text{
        max-width: 380px;
        margin: 0 0 0 auto;
        text-align: justify;
        
        @include media_query(){
            p{
                font-size: 1.3rem;
            }
        }
    }
}