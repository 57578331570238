.workflow{
    margin-top: 150px;
    
    @include media_query(){
        margin-top: 100px;
        padding: 0 18px;
    }
    
    h4{
        display: flex;
        align-items: center;
        font-size: 2.1rem;
        font-weight: bold;
        margin-bottom: 40px;
        
        &::before{
            display: block;
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #59B52F;
            margin-right: 5px;
        }
    }
    
    .ofs{
        @include media_query(){
            max-width: 100%;
            height: auto;
            overflow: scroll;
        }
    }
    img{
        width: 100%;
        height: auto;
        vertical-align: bottom;
        
        @include media_query(){
            height: 475px;
            width: auto;
        }
    }
}

.jobcategorys{
    margin-top: 170px;
    
    @include media_query(){
        margin-top: 100px;
        padding: 0 18px;
    }
    
    .jobcategory_item{
        // display: flex;
        gap: 50px;
        display: grid;
        grid-template-columns: 1fr 270px;
        
        @include media_query(){
            grid-template-columns: 1fr;
            // flex-direction: column;
            gap: 30px;
        }
        
        &:nth-child(even){
            grid-template-columns: 270px 1fr;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
            
            .jobcategory_item_text{
                order: 1;
            }
        }
        
        .jobcategory_item_text{
            padding-top: 45px;
            
            @include media_query(){
                order: 1;
                padding: 0;
            }
            
            h3{
                font-size: 3.2rem;
                font-weight: bold;
                margin-bottom: 45px;
                
                @include media_query(){
                    font-size: 2.4rem;
                    margin-bottom: 25px;
                }
            }
            
            p{
                font-size: 1.7rem;
                line-height: 1.9;
                font-weight: bold;
                
                @include media_query(){
                    font-size: 1.4rem;
                }
            }
            
            .btn_viewmore{
                margin-top: 60px;
                
                a{
                    display: block;
                    width: 240px;
                    padding: 20px;
                    font-size: 1.3rem;
                    text-align: center;
                    color: #fff;
                    letter-spacing: .08em;
                    
                    border-radius: 30px;
                    background: linear-gradient(-70deg, #59B599, #59B52F);
                    
                    transition-property: opacity;
                    transition-duration: .25s;
                    
                    @include media_query(){
                        margin: 0 auto;
                    }
                    
                    &:hover{
                        opacity: .7;
                    }
                }
            }
        }
        
        .jobcategory_item_img{
            // max-width: 450px;
            
            @include media_query(){
                // margin: 0 auto;
                text-align: center;
            }
            
            img{
                width: 100%;
                height: auto;
                // max-width: 100%;
                // height: auto;
                
                @include media_query(){
                    max-width: 293px;
                }
            }
        }
        
        & + .jobcategory_item{
            margin-top: 170px;
            
            @include media_query(){
                margin-top: 100px;
            }
        }
    }
}




.jobcategory_single{
    .jobcategory_single_head{
        margin-top: 50px;
        
        .jobcategory_single_illust{
            text-align: center;
            
            img{
                max-width: 335px;
                height: auto;
                
                @include media_query(){
                    max-width: 202px;
                }
            }
        }
        
        h3{
            text-align: center;
            font-size: 3.6rem;
            margin-top: 80px;
            
            @include media_query(){
                font-size: 2.6rem;
                margin-top: 30px;
            }
            
            span{
                display: block;
                text-align: center;
                font-size: 1.6rem;
                color: #59B52F;
                margin-bottom: 10px;
                
                &::after{
                    display: block;
                    content: "";
                    width: 47px;
                    height: 1px;
                    background-color: #59B52F;
                    margin: 15px auto 0;
                }
                
                @include media_query(){
                    font-size: 1.3rem;
                }
            }
        }
    }
    
    .jobcategory_single_item_wrap{
        margin-top: 150px;
        
        @include media_query(){
            margin-top: 100px;
        }
        
        .jobcategory_single_item{
            
            & + .jobcategory_single_item{
                margin-top: 200px;
                
                @include media_query(){
                    margin-top: 100px;
                }
            }
            
            .jobcategory_single_item_inner{
                display: flex;
                gap: 60px;
                
                @include media_query(){
                    flex-direction: column;
                    padding: 0 20px;
                    gap: 25px;
                }
                
                .jobcategory_single_item_contents{
                    flex: 1;
                    padding-top: 35px;
                    
                    @include media_query(){
                        padding-top: 0;
                    }
                    
                    h4{
                        span{
                            display: inline-block;
                            font-size: 1.6rem;
                            color: #59B52F;
                            padding: .3em 1em;
                            border: solid 1px #59B599;
                            border-radius: 30px;
                            margin-bottom: 10px;
                        }
                        
                        font-size: 2.4rem;
                        font-weight: bold;
                        
                        @include media_query(){
                            font-size: 1.8rem;
                        }
                    }
                    
                    p{
                        font-size: 1.7rem;
                        line-height: 1.9;
                        margin-top: 35px;
                        
                        @include media_query(){
                            font-size: 1.4rem;
                            margin-top: 20px;
                        }
                    }
                }
                
                .jobcategory_single_item_img{
                    img{
                        width: 100%;
                        max-width: 440px;
                        height: auto;
                    }
                }
            }
            
            .jobcategory_interview{
                margin-top: 60px;
                position: relative;
                background-color: #E3F8F4;
                padding: 40px 50px;
                
                @include media_query(){
                    margin-top: 50px;
                    padding: 25px 20px;
                }
                
                h5{
                    position: absolute;
                    left: 30px;
                    top: -18px;
                    
                    img{
                        max-width: 170px;
                        height: auto;
                    }
                    
                    @include media_query(){
                        left: auto;
                        right: 20px;
                        top: -10px;
                        
                        img{
                            max-width: 140px;
                        }
                    }
                }
                
                .jobcategory_interview_contents{
                    display: grid;
                    grid-template-columns: 190px 1fr;
                    grid-template-rows: auto auto;
                    grid-template-areas:
                        "img title"
                        "img text";
                    // display: flex;
                    // gap: 30px;
                    
                    @include media_query(){
                        grid-template-columns: 128px 1fr;
                        grid-template-areas:
                            "img title"
                            "text text";
                    }
                    
                    .jobcategory_interview_img{
                        width: 100%;
                        max-width: 160px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 30px;
                        aspect-ratio: 1 / 1;
                        
                        grid-area: img;
                        
                        @include media_query(){
                            grid-area: img;
                            margin-right: 25px;
                        }
                        
                        img{
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            aspect-ratio: 1 / 1;
                            
                            @include media_query(){
                                max-width: 103px;
                            }
                        }
                    }
                    
                    h6{
                        grid-area: title;
                        
                        font-size: 1.8rem;
                        line-height: 1.8;
                        font-weight: bold;
                        
                        @include media_query(){
                            grid-area: title;
                            padding-top: 20px;
                            font-size: 1.5rem;
                        }
                    }
                    
                    p{
                        grid-area: text;
                        
                        font-size: 1.4rem;
                        line-height: 1.8;
                        margin-top: 15px;
                        
                        @include media_query(){
                            grid-area: text;
                        }
                        
                        span{
                            display: block;
                            font-size: 1.4rem;
                            line-height: 1.8;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}