.culture_head{
    margin-bottom: 80px;
    
    @include media_query(){
        margin-bottom: 50px;
    }
    
    img{
        width: 100%;
        height: auto;
    }
}

.culture_list{
    max-width: 880px;
    margin: 80px auto 0;
    
    @include media_query(){
        display: flex;
        margin: 50px auto 0;
        padding: 0;
    }
    
    dl{
        display: flex;
        align-items: center;
        border-top: solid 3px #000;
        border-bottom: solid 1px #B2B2B2;
        padding: 0 30px;
        
        @include media_query(){
            //display: block;
            border-top: solid 1px #B2B2B2;
            width: 100%;
            padding: 0 10px 0;
        }
        
        &:not(:last-child){
            margin-bottom: 50px;
            
            @include media_query(){
                margin-bottom: 0;
            }
        }
        
        dt{
            font-size: 1.6rem;
            font-weight: bold;
            margin-right: auto;
            
            @include media_query(){
                display: none;
                margin-bottom: 20px;
                font-size: 1.5rem;
                text-align: center;
            }
        }
        
        dd{
            @include media_query(){
                text-align: center;
                margin: 0 auto;
            }
            
            &:not(:last-child){
                margin-right: 50px;
                
                @include media_query(){
                    margin-right: 0;
                }
            }
            
            a{
                display: block;
                padding: 30px 0;
                font-size: 1.4rem;
                color: #000;
                
                @include media_query(){
                    padding: 20px 0;
                }
                
                &:hover{
                    opacity: .7;
                }
            }
            
            span{
                display: block;
                padding: 30px 0;
                font-size: 1.4rem;
                color: #59B52F;
                
                @include media_query(){
                    padding: 12px 0;
                }
            }
        }
    }
    
}

.culture_contents{
    max-width: 880px;
    margin: 0 auto;
}

.culture_contents_head{
    margin: 100px 0 0;
    
    @include media_query(){
        margin: 50px 0 0;
        padding: 0 25px;
    }
    
    h3{
        text-align: center;
        font-size: 3.6rem;
        
        @include media_query(){
            font-size: 2.6rem;
        }
        
        span{
            display: block;
            text-align: center;
            font-size: 1.4rem;
            color: #59B52F;
            margin-bottom: 10px;
            
            @include media_query(){
                font-size: 1.3rem;
            }
        }
    }
    
    p{
        font-size: 1.5rem;
        margin-top: 50px;
        line-height: 1.8;
        text-align: center;
        
        @include media_query(){
            margin-top: 30px;
        }
    }
}

.benefits_main{
    & + .benefits_main{
        margin-top: 120px;
        
        @include media_query(){
            margin-top: 80px;
        }
    }
    
    .benefits_main_inner{
        display: flex;
        flex-wrap: wrap;
        margin-left: -80px;
        
        @include media_query(){
            margin-left: 0;
            padding: 0 25px;
        }
    }
    
    h3{
        font-size: 2.8rem;
        color: #59B52F;
        font-weight: bold;
        line-height: 1.5;
        
        @include media_query(){
            font-size: 2.2rem;
            padding: 0 25px;
        }
        
        &::after{
            display: block;
            content: "";
            width: 50px;
            height: 2px;
            margin-top: 30px;
            background-color: #B2B2B2;
            
            @include media_query(){
                margin-top: 20px;
            }
        }
    }
    
    .benefits_item{
        width: calc(50% - 80px);
        margin-left: 80px;
        margin-top: 80px;
        
        @include media_query(){
            width: 100%;
            margin: 50px 0 0 0;
        }
        
        img{
            width: 100%;
            height: auto;
            margin-bottom: 30px;
            
            @include media_query(){
                margin-bottom: 20px;
            }
        }
        
        h4{
            font-size: 2rem;
            margin-bottom: 20px;
            
            @include media_query(){
                font-size: 1.8rem;
                margin-bottom: 10px;
            }
            
            span{
                font-size: 1.3rem;
                color: #595757;
            }
        }
        
        p{
            font-size: 1.6rem;
            line-height: 1.8;
            
            @include media_query(){
                font-size: 1.4rem;
            }
        }
    }
}




.about_educational{
    margin-top: 100px;
    
    @include media_query(){
        margin-top: 60px;
    }
    
    h3{
        font-size: 2.8rem;
        color: #59B52F;
        font-weight: bold;
        line-height: 1.5;
        
        @include media_query(){
            font-size: 2.2rem;
            padding: 0 25px;
        }
        
        &::after{
            display: block;
            content: "";
            width: 50px;
            height: 2px;
            margin-top: 30px;
            background-color: #B2B2B2;
            
            @include media_query(){
                margin-top: 20px;
            }
        }
    }
}

.about_educational_inner{
    margin-top: 90px;
    padding: 0 40px;
    
    @include media_query(){
        padding: 0 25px;
    }
    
    @include media_query(){
        margin-top: 50px;
        padding: 0 25px;
    }
    
    h4{
        font-size: 2.1rem;
        margin-bottom: 20px;
    }
    
    p{
        font-size: 1.6rem;
        line-height: 1.5;
        margin-bottom: 60px;
    }
    
    img{
        max-width: 640px;
        width: 100%;
        height: auto;
    }
    
    .educational_schedule{
        
        li{
            list-style: none;
            
            p{
                list-style: none;
                background-color: #F8FACC;
                border: solid 1px #58B32D;
                color: #59B52E;
                font-weight: bold;
                
                font-size: 2rem;
                line-height: 1.5;
                text-align: center;
                padding: 1em;
                margin-bottom: 0;
                
                @include media_query(){
                    font-size: 1.4rem;
                }
            }
                
            &:not(:last-child){
                &::after{
                    content: "";
                    display: block;
                    width: 29px;
                    height: 14px;
                    margin: 25px auto;
                    background-image: url(../img/culture/arrow_down.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    
                    @include media_query(){
                        margin: 16px auto;
                    }
                }
            }
        }
    }
}

.related{
    font-size: 1.4rem;
    line-height: 2;
    margin-top: 60px;
    padding-left: 145px;
    
    @include media_query(){
        padding: 0 25px;
        font-size: 1.2rem;
    }
    
    h4{
        display: flex;
        align-items: center;
        
        &::before{
            display: block;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #59B52F;
            margin-right: 10px;
        }
    }
    
    h5{
        margin-top: 10px;
    }
    
    ul{
        li{
            list-style: none;
        }
    }
}

.slider_wrap{
    //width: 100%;
    margin-top: 60px;
    
    img{
        width: 100%;
        height: auto;
    }
    
    p{
        font-size: 1.4rem;
        text-align: center;
        margin-top: 20px;
    }
    
    .slick-slide{
        //width: 100% !important;
    }
    
    .slider{
        li{
            padding: 10px;
            
            @include media_query(){
                padding: 0;
            }
        }
    }
    
    
    .slick-arrow{
        background-size: cover;
        width: 30px;
        height: 30px;
        z-index: 999;
        margin-top: -22px;
        
        &::before{
            display: none;
        }
    }
    
    .slick-prev{
        left: 20px;
        background: url(../img/common/arrow_l.png);
        background-size: cover;
        
        &:hover{
            background-image: url(../img/common/arrow_l.png) !important;
            background-size: cover;
            opacity: .7;
        }
    }
    
    .slick-next{
        right: 20px;
        background: url(../img/common/arrow_r.png);
        background-size: cover;
        
        &:hover{
            background-image: url(../img/common/arrow_r.png) !important;
            background-size: cover;
            opacity: .7;
        }
    }
}