.entry_introduction{
    font-size: 1.5rem;
    line-height: 2;
    margin: 80px 0 150px;
    text-align: center;
    
    @include media_query(){
        margin: 30px 0 50px;
        padding: 0 25px;
        text-align: left;
        font-size: 1.4rem;
    }
}

.requirements_introduction{
    max-width: 880px;
    font-size: 1.5rem;
    line-height: 2;
    margin: 80px auto 150px;
    
    @include media_query(){
        margin: 30px 0 50px;
        padding: 0 25px;
        text-align: left;
        font-size: 1.4rem;
    }
    
    h3{
        text-align: center;
        font-weight: bold;
        margin-bottom: 15px;
    }
}

.entry_head{
    margin-bottom: 80px;
    
    img{
        width: 100%;
        height: auto;
    }
    
    .section_header{
        .section_title{
            img{
                max-width: 150px;
            }
        }
    }
}


.entry_btns{
    @include media_query(){
        padding: 0 20px;
    }
    
    ul{
        
        & + ul{
            margin-top: 80px;
            
            @include media_query(){
                margin-top: 30px;
            }
        }
        
        & + .on_text{
            margin-top: 30px;
            
            @include media_query(){
                margin-top: 30px;
            }
        }
        
        li{
            
            list-style: none;
            
            &:not(:last-child){
                margin-bottom: 80px;
                
                @include media_query(){
                    margin-bottom: 30px;
                }
            }
            
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 800px;
                height: 240px;
                color: #fff;
                font-size: 2.4rem;
                margin: 0 auto;
                
                @include media_query(){
                    height: 120px;
                    flex-direction: column;
                    font-size: 1.5rem;
                    background-position: center center;
                }
                
                &:hover{
                    opacity: .7;
                }
            }
        }
        
        // .internship_btn{
        //     margin-bottom: 80px !important;
        // 
        //     @include media_query(){
        //         margin-bottom: 30px !important;
        //     }
        // }
        
        .internship{
            a{
                flex-direction: column;
                background-image: url(../img/entry/button_internship.jpg);
                background-size: cover;
                
                img{
                    max-width: 200px;
                    margin-top: 10px;
                    //margin-left: 20px;
                    
                    @include media_query(){
                        max-width: 120px;
                        margin: 10px 0 0 0;
                    }
                }
            }
        }
        
        .freshman{
            a{
                flex-direction: column;
                background-image: url(../img/entry/button_freshman.jpg);
                background-size: cover;
                
                img{
                    max-width: 200px;
                    margin-top: 10px;
                    //margin-left: 20px;
                    
                    @include media_query(){
                        max-width: 120px;
                        margin: 10px 0 0 0;
                    }
                }
            }
        }
        
        .career{
            a{
                background-image: url(../img/entry/button_career.jpg);
                background-size: cover;
            }
        }
        
        .fixed-term{
            a{
                background-image: url(../img/entry/button_fixed-term.jpg);
                background-size: cover;
            }
        }
    }
}

.on_text, .internship_text{
    margin-bottom: 80px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    
    & + .on_text{
        margin-top: 50px;
        
        @include media_query(){
            margin-top: 30px;
        }
    }
    
    @include media_query(){
        font-size: 1.2rem;
        margin-bottom: 30px;
    }
}

.contact{
    background-color: #EAFAF7;
    margin-top: 150px;
    padding: 100px 0;
    
    @include media_query(){
        margin-top: 50px;
        padding: 50px 25px;
    }
}

.contact_inner{
    max-width: 680px;
    margin: 0 auto;
    
    h2{
        font-size: 2.8rem;
        text-align: center;
        margin-bottom: 50px;
        
        @include media_query(){
            margin-bottom: 30px;
        }
        
        span{
            display: block;
            font-size: 1.4rem;
            color: #59B52F;
            margin-bottom: 20px;
        }
    }
    
    p{
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.5;
    }
}

.form_main{
    margin-top: 70px;
    
    @include media_query(){
        margin-top: 50px;
    }
    
    p{
        text-align: left;
    }
    
    .required_text{
        font-size: 1.4rem;
        font-weight: bold;
        color: #f00;
        padding-bottom: 25px;
        margin-bottom: 40px;
        border-bottom: solid 1px #B2B2B2;
    }
    
    p + p{
        margin-top: 20px;
    }
    
    .wpcf7-not-valid-tip{
        margin-top: 10px;
    }
    
    .wpcf7-response-output.wpcf7-validation-errors{
        border: none;
        background-color: #ffcbcb;
        color: #f00;
        font-size: 1.5rem;
        padding: .8em 1em;
    }
    
    .wpcf7-response-output.wpcf7-mail-sent-ok{
        border: none;
        background-color: #d5ffcb;
        color: #4dcb46;
        font-size: 1.5rem;
        padding: .8em 1em;
    }
    
    label{
        display: block;
        font-size: 1.5rem;
        line-height: 1.5;
        font-weight: bold;
        
        & + input[type=text], & + input[type=email], & + input[type=tel], & + textarea{
            margin-top: 10px;
        }
    }
    
    input[type=text], input[type=email], input[type=tel], textarea{
        display: block;
        width: 100%;
        background-color: #fff;
        padding: 20px 25px;
        font-size: 1.5rem;
        border: none !important;
        @include media_query(){
            padding: 15px 20px;
        }
        
        & + label{
            margin-top: 35px;
            
            @include media_query(){
                margin-top: 25px;
            }
        }
        
        &[readonly]{
            background-color: #ececec;
            border: none;
        }
    }
    
    .wpcf7-list-item{
        margin: -10px 0 0;
    }
    
    input[type=submit]{
        display: block;
        width: 195px;
        margin: 50px auto 0;
        padding: 20px;
        font-size: 1.5rem;
        text-align: center;
        color: #fff;
        letter-spacing: .08em;
        
        border-radius: 30px;
        background: linear-gradient(-70deg, #59B599, #59B52F);
        
        transition-property: opacity;
        transition-duration: .25s;
        
        @include media_query(){
            max-width: 240px;
            width: 100%;
            margin: 50px auto 0;
        }
        
        &:hover{
            opacity: .7;
        }
    }
    
    input[type=button]{
        display: block;
        width: 195px;
        margin: 50px auto 0;
        padding: 20px;
        font-size: 1.5rem;
        text-align: center;
        color: #fff;
        letter-spacing: .08em;
        
        border-radius: 30px;
        background: #a4a4a4;
        
        transition-property: opacity;
        transition-duration: .25s;
        
        @include media_query(){
            max-width: 240px;
            width: 100%;
            margin: 50px auto 0;
        }
        
        &:hover{
            opacity: .7;
        }
    }
    
    .agree_dl{
        margin-top: 70px;
        border-top: solid 1px #B2B2B2;
        padding-top: 20px;
        
        display: flex;
        
        dt{
            font-size: 1.5rem;
            font-weight: bold;
        }
        
        dd{
            flex: 1;
            font-size: 1.4rem;
            margin-left: 20px;
            
            label{
                font-weight: normal;
            }
            
            input[type=checkbox]{
                margin-top: -2px;
            }
            
            p{
                margin-top: 10px;
                
                a{
                    color: #000;
                    text-decoration: underline;
                    
                    &:hover{
                        opacity: .7;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.requirements_list{
    max-width: 880px;
    margin: 0 auto;
    
    @include media_query(){
        display: flex;
        padding: 0 20px;
        margin-left: -10px;
    }
    
    dl{
        display: flex;
        align-items: center;
        border-top: solid 1px #B2B2B2;
        border-bottom: solid 1px #B2B2B2;
        padding: 0 30px;
        
        @include media_query(){
            display: block;
            width: calc(50% - 10px);
            padding: 25px 10px;
            margin-left: 10px;
        }
        
        &:not(:last-child){
            margin-bottom: 50px;
            
            @include media_query(){
                margin-bottom: 0;
            }
        }
        
        dt{
            font-size: 1.6rem;
            font-weight: bold;
            margin-right: auto;
            
            @include media_query(){
                margin-bottom: 20px;
                font-size: 1.5rem;
                text-align: center;
            }
        }
        
        dd{
            @include media_query(){
                text-align: center;
            }
            
            &:not(:last-child){
                margin-right: 16px;
                
                @include media_query(){
                    margin-right: 0;
                }
            }
            
            a{
                display: block;
                padding: 30px 0;
                font-size: 1.4rem;
                color: #000;
                
                @include media_query(){
                    padding: 12px 0;
                }
                
                &[href]:hover{
                    opacity: .7;
                }
            }
            
            span{
                display: block;
                padding: 30px 0;
                font-size: 1.4rem;
                color: #59B52F;
                font-weight: bold;
                
                @include media_query(){
                    padding: 12px 0;
                }
            }
        }
    }
    
}




.requirements{
    max-width: 880px;
    margin: 80px auto 0;
    
    @include media_query(){
        margin: 60px auto 0;
    }
    
    h3{
        font-size: 2.6rem;
        text-align: center;
        margin-bottom: 20px;
        
        @include media_query(){
            font-size: 2rem;
        }
    }
    
    h4{
        font-size: 2rem;
        text-align: center;
        
        @include media_query(){
            font-size: 1.6rem;
        }
    }
}

.requirements_table{
    width: 100%;
    margin-top: 60px;
    
    @include media_query(){
        display: block;
        margin-top: 30px;
    }
    
    tr{
        @include media_query(){
            display: block;
        }
        
        th,td{
            padding: 25px 30px;
            text-align: left;
            line-height: 1.8;
            
            @include media_query(){
                display: block;
                padding: 15px 20px;
            }
        }
        
        th{
            font-size: 1.5rem;
            font-weight: bold;
            white-space: nowrap;
            border-top: solid 3px #59B52F;
            
            @include media_query(){
                text-align: center;
                font-size: 1.4rem;
            }
        }
        
        td{
            font-size: 1.5rem;
            border-top: solid 1px #B2B2B2;
            
            @include media_query(){
                font-size: 1.4rem;
            }
        }
    }
}

.mynavientry_btn{
    text-align: center;
    margin: 90px 0 0 0;
}

.recruit_schedule{
    max-width: 880px;
    margin: 80px auto 30px;
    
    h3{
        font-size: 2.6rem;
        text-align: center;
        margin: 0 0 30px;
    }
    
    .educational_schedule{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 20px 0;
        
        li{
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style: none;
            width: 100%;
            @include media_query(){
                
                flex-direction: column;
            }
            
            p{
                width: 100%;
                list-style: none;
                background-color: #F8FACC;
                border: solid 1px #58B32D;
                color: #59B52E;
                font-weight: bold;
                
                font-size: 2rem;
                line-height: 1.5;
                text-align: center;
                padding: 1em;
                margin-bottom: 0;
                
                @include media_query(){
                    width: 100%;
                    font-size: 1.4rem;
                }
            }
                
            &:not(:last-child){
                &::after{
                    content: "";
                    display: block;
                    width: 29px;
                    height: 14px;
                    margin: 25px 10px;
                    background-image: url(../img/culture/arrow_down.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    
                    @include media_query(){
                        transform: rotate(0);
                        margin: 16px auto;
                    }
                }
            }
        }
    }
}